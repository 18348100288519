import {
  CreateWcClaimAttachmentUrlCommand,
  CreateWcClaimAttachmentInput,
} from "@amzn/ttechclaimintakeservice-client";

import { getAuthenticationToken } from "../auth/helpers";
import { setupClaimIntakeClient } from "./setupClaimIntakeClient";
import { MetricsPublisher } from "../metrics/metrics";

const RADIX_DECIMAL = 10;
export async function createWcClaimAttachmentUrlService(
  input: CreateWcClaimAttachmentInput
) {
  const token = await getAuthenticationToken();
  if (!token) {
    return null;
  }
  const client = setupClaimIntakeClient(token);
  const command = new CreateWcClaimAttachmentUrlCommand(input);
  const metricsPublisher = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService"
  );
  const metricsPublisherError = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService.error"
  );
  return await client
    .send(command)
    .then((response: any) => {
      metricsPublisher.publishFileUploadURLLatencyMetrics();
      metricsPublisher.publishFileUploadURLSuccessMetrics();
      return response.url;
    })
    .catch((error: any) => {
      metricsPublisher.publishFileUploadURLLatencyMetrics();
      metricsPublisher.publishFileUploadURLFailureMetrics();
      metricsPublisherError.publishCreateClaimURLError(error.name);
      throw error;
    });
}

export async function uploadWcClaimAttachment(
  input: CreateWcClaimAttachmentInput,
  file: File
) {
  const metricsPublisher = new MetricsPublisher(
    "api.createWcClaimAttachmentUrlService"
  );
  let retries = 2;
  while (retries) {
    try {
      const url = await createWcClaimAttachmentUrlService(input);
      retries = 0;
      if (typeof url !== "string") {
        throw new Error("Invalid URL");
      }
      // Valid URL attempt to upload the file
      return await attemptFileUpload(url, file);
    } catch (error: any) {
      retries -= 1;
      metricsPublisher.publishFileUploadURLRetryMetrics();
      if (!retries) {
        throw error;
      }
    }
  }
}

async function attemptFileUpload(url: string, file: File) {
  const successStatus = await uploadFiles(url, file);
  if (successStatus.ok) {
    return successStatus.ok;
  } else {
    // If file upload fails the first time make a second attempt.
    // Then return status with either success/failure.
    const retryStatus = await uploadFiles(url, file);
    return retryStatus.ok;
  }
}

async function uploadFiles(url: string, file: File) {
  const metricsPublisher = new MetricsPublisher("api.uploadWcClaimAttachment");
  const metricsPublisherError = new MetricsPublisher(
    "api.uploadWcClaimAttachment.error"
  );
  const response = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
    },
  });
  if (response.ok) {
    metricsPublisher.publishFileUploadSuccessMetrics();
    metricsPublisher.publishFileUploadLatencyMetrics();
    return response;
  } else {
    metricsPublisher.publishFileUploadFailureMetrics();
    metricsPublisher.publishFileUploadRetryMetrics();
    metricsPublisherError.publishFileUploadError(
      response.status.toString(RADIX_DECIMAL)
    );
    return response;
  }
}
