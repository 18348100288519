import {
  AttachmentInput,
  CreateWcClaimCommandInput,
  PhysicianInformationInput,
  SiteDetailsInput,
  WitnessInformationInput,
} from "@amzn/ttechclaimintakeservice-client";
import moment from "moment-timezone";
import {
  FormFieldConfigType,
  FormFieldStateType,
  FormStateType,
  INPUT_TYPES,
  SelectOptionType,
} from "src/forms/types";
import { S3StagedFile } from "src/components/fileUpload/interfaces";
import { store } from "src/redux/store";
import {
  ALL_FILE_UPLOAD_FIELDS,
  ASSOCIATE_INFORMATION_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  SEDGWICK,
} from "src/forms/constants";
import {
  CreateWcClaimModelAdditionalFields,
  CreateWcClaimProcessingModel,
} from "./types";
import { stringToBoolean } from "src/forms/tools";
import {
  NON_COMPANY_LOCATION,
  WORKING_FROM_HOME_EMPLOYEES_ADDRESS,
} from "src/forms/options";

export function buildWcCreateClaimInput(
  formState: FormStateType,
  formConfig: FormFieldConfigType[],
  additionalFields: CreateWcClaimModelAdditionalFields
): CreateWcClaimCommandInput {
  const tempInputModel: CreateWcClaimCommandInput = {};

  processFields(formState, formConfig, tempInputModel);
  const createClaimInputModel: CreateWcClaimCommandInput = {
    ...postProcessModel(tempInputModel, additionalFields),
  };
  return createClaimInputModel;
}
const shouldFieldBeSkipped = (
  fieldConfig: FormFieldConfigType,
  fieldState: FormFieldStateType
) => {
  const hiddenFieldsIncluded = [
    ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE,
    ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS,
    ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME,
    INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE,
  ];
  return (
    !fieldConfig ||
    !fieldConfig?.name ||
    fieldState?.ignoreInFinalForm ||
    (fieldState?.hidden &&
      !hiddenFieldsIncluded.includes(
        fieldConfig.name as ASSOCIATE_INFORMATION_CONSTANTS
      ))
  );
};

const handleSpecialCases = (
  fieldName: string,
  fieldState: FormFieldStateType,
  model: CreateWcClaimProcessingModel
) => {
  /**
   * Handle specific cases for individual fields.
   */
  if (ALL_FILE_UPLOAD_FIELDS.includes(fieldName)) {
    model[fieldName as keyof CreateWcClaimProcessingModel] = buildAttachments(
      fieldState.value
    );
    return true;
  }
  switch (fieldName) {
    case INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT:
      model[INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT:
      model[INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY:
      model[INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY] =
        fieldState.value?.value;
      model[INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY_DESCRIPTION] =
        fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY:
      model[INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY] =
        fieldState.value?.value;
      model[
        INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY_DESCRIPTION
      ] = fieldState.value?.label;
      return true;

    case INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST:
      model[INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST] =
        fieldState.value?.map((entry: SelectOptionType) => {
          return entry.value;
        });
      return true;

    default:
      return false;
  }
};

const handleBodyPartFields = (
  fieldConfig: FormFieldConfigType,
  formState: FormStateType,
  model: CreateWcClaimProcessingModel
) => {
  if (fieldConfig.name.includes("bodyPartInformation") && fieldConfig.fields) {
    const injuredBodyPartFieldName = fieldConfig.fields[0].name;
    const injuredSideFieldName = fieldConfig.fields[1].name;
    const natureOfInjuryFieldName = fieldConfig.fields[2].name;

    const injuredBodyPart = formState[injuredBodyPartFieldName];
    const injuredSide = formState[injuredSideFieldName];
    const natureOfInjury = formState[natureOfInjuryFieldName];

    if (injuredBodyPart && injuredBodyPart.value?.value) {
      model[injuredBodyPartFieldName] = injuredBodyPart.value?.value;
      model[`${injuredBodyPartFieldName}Description`] =
        injuredBodyPart.value?.label;
    }
    if (injuredSide && injuredSide.value?.value) {
      model[injuredSideFieldName] = injuredSide.value?.value;
    }
    if (natureOfInjury && natureOfInjury.value?.value) {
      model[natureOfInjuryFieldName] = natureOfInjury.value?.value;
      model[`${natureOfInjuryFieldName}Description`] =
        natureOfInjury.value?.label;
    }
    return true;
  }
  return false;
};

const processFields = (
  formState: FormStateType,
  formConfig: FormFieldConfigType[],
  model: CreateWcClaimProcessingModel
) => {
  for (const fieldConfig of formConfig) {
    const fieldState = formState[fieldConfig.uniqueName || fieldConfig.name];
    if (shouldFieldBeSkipped(fieldConfig, fieldState)) {
      continue;
    }
    const fieldName = fieldConfig.name.split("_")[0];
    if (fieldConfig.type === INPUT_TYPES.FIELD_GROUP) {
      if (handleBodyPartFields(fieldConfig, formState, model)) {
        continue;
      }
      model[fieldConfig.name] = {};
      processFields(formState, fieldConfig.fields, model[fieldConfig.name]);
    } else {
      if (handleSpecialCases(fieldName, fieldState, model)) {
        continue;
      }
      const value =
        fieldState?.value?.value || stringToBoolean(fieldState.value);
      model[fieldConfig.name] = value;
    }
  }
};

const postProcessSiteInformation = (
  model: CreateWcClaimProcessingModel,
  additionalFields: CreateWcClaimModelAdditionalFields
) => {
  const sitesState = store.getState().sites;
  model.associateSiteCode = sitesState.associateHomeSite?.unitNumber || "";

  const shouldIncidentSiteEqualHomeSite =
    model.homeIncidentSite ||
    model.locationType === WORKING_FROM_HOME_EMPLOYEES_ADDRESS.value ||
    model.locationType === NON_COMPANY_LOCATION.value;
  model.incidentSiteName = shouldIncidentSiteEqualHomeSite
    ? model.associateHomeSite
    : model.incidentSiteName;
  model.incidentSiteUnitNumber = shouldIncidentSiteEqualHomeSite
    ? model.associateSiteCode
    : additionalFields.incidentSite?.unitNumber || "";

  model.incidentSiteDetails = shouldIncidentSiteEqualHomeSite
    ? (additionalFields.associateHomeSite as SiteDetailsInput)
    : (additionalFields.incidentSite as SiteDetailsInput);

  model.associateHomeSiteDetails =
    additionalFields.associateHomeSite as SiteDetailsInput;
  model.notificationAliasList =
    additionalFields?.associateHomeSite?.notificationAliasList;
  model.notificationWebhook =
    additionalFields?.associateHomeSite?.notificationWebhook;

  model.benefitState = model.incidentSiteDetails?.state || "";
};

const postProcessMedicalInformation = (model: CreateWcClaimProcessingModel) => {
  const physicianInformationList: PhysicianInformationInput[] = [];
  const witnessInformationList: WitnessInformationInput[] = [];

  if (model.physicianInformation_0) {
    physicianInformationList.push(model.physicianInformation_0);
  }
  if (model.physicianInformation_1) {
    physicianInformationList.push(model.physicianInformation_1);
  }

  if (model.witnessInformation_0) {
    witnessInformationList.push({
      ...model.witnessInformation_0,
    });
  }
  if (model.witnessInformation_1) {
    witnessInformationList.push({
      ...model.witnessInformation_1,
    });
  }
  model.physicianInformationList = physicianInformationList;
  model.witnessInformationList = witnessInformationList;

  delete model.physicianInformation_0;
  delete model.physicianInformation_1;
  delete model.witnessInformation_0;
  delete model.witnessInformation_1;
};

const postProcessQuestionableClaimReasons = (
  model: CreateWcClaimProcessingModel
) => {
  if (!model.questionableClaim) {
    delete model.questionableClaimReasons;
    delete model.questionableClaimAdditionalReason;
    return;
  }
  let questionableClaimReasons: string[] = [];
  let questionableClaimAdditionalReason = "";
  if (model.questionableClaimGroup) {
    questionableClaimReasons =
      model.questionableClaimGroup.questionableClaimReasons.map(
        (entry: SelectOptionType) => {
          return entry.value;
        }
      );
    questionableClaimAdditionalReason =
      model.questionableClaimGroup.questionableClaimAdditionalReason;
  }
  model.questionableClaimReasons = questionableClaimReasons;
  model.questionableClaimAdditionalReason = questionableClaimAdditionalReason;
};
const postProcessModel = (
  model: CreateWcClaimProcessingModel,
  additionalFields: CreateWcClaimModelAdditionalFields
) => {
  let newModel: CreateWcClaimProcessingModel = {};
  //Remove top level groupFields
  for (const tempFields of Object.values(model)) {
    newModel = { ...newModel, ...tempFields };
  }

  //Handle individual field requirements
  postProcessSiteInformation(newModel, additionalFields);
  postProcessMedicalInformation(newModel);
  postProcessQuestionableClaimReasons(newModel);

  //Add all fields to model
  newModel = {
    ...newModel,
    incidentDate: newModel.incidentDate?.replace(/-/g, "/"),
    employerNotifiedDate: newModel.employerNotifiedDate?.replace(/-/g, "/"),
    timezone: moment.tz.guess(),
    draft: false,
    testRecord: false,
    thirdPartyAdjuster: additionalFields?.workersCompensationTpa || SEDGWICK,
    claimReporterFirstName: additionalFields?.user?.givenName || "",
    claimReporterLastName: additionalFields?.user?.familyName || "",
    claimReporterAlias: additionalFields?.user?.id || "",
    claimReporterJobDescription: additionalFields?.user?.jobDescription || "",
    amazonClaimReferenceId: additionalFields?.amazonClaimReferenceId || "",
  };

  if (!newModel.questionableClaim) {
    delete newModel.questionableClaimReasons;
    delete newModel.questionableClaimAdditionalReason;
  }
  return newModel;
};

const buildAttachments = (value: S3StagedFile[]): AttachmentInput[] => {
  const fileAttachments: AttachmentInput[] = [];

  value.forEach((file) => {
    const attachment = {
      fileExtension: file.name.split(".").pop(),
      s3Key: file.s3Key,
      fileName: file.name,
      fileSize: file.size,
    };

    fileAttachments.push(attachment);
  });

  return fileAttachments;
};
