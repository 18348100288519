import { FormOverride } from "../../types";
import {
  INCIDENT_INFORMATION_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
} from "../../constants";
import {
  ANCHOR_ACCIDENT_OPTIONS,
  ANCHOR_BODY_PART_OPTIONS,
  ANCHOR_CAUSES_OPTIONS,
  ANCHOR_INJURY_OPTIONS,
  CONCERNS_OPTIONS,
} from "src/forms/options";

export const anchorFormOverride: FormOverride = {
  state: {
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
  },
  config: {
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      options: ANCHOR_INJURY_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      label: "Accident Type",
      placeholderText: "Please select the type of accident that occurred",
      options: ANCHOR_ACCIDENT_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT]: {
      label: "Cause of Injury",
      options: ANCHOR_CAUSES_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      options: ANCHOR_BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      options: ANCHOR_BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS]: {
      options: CONCERNS_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      contained: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      contained: false,
    },
  },
  validation: {
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      required: { value: false },
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST]: {
      required: { value: true },
    },
  },
};
