import {
  INPUT_TYPES,
  CUSTOM_VALIDATION_TYPES,
  VALIDATION_TYPES,
  FormFieldType,
  FormStateType,
  TEXT_INPUT_TYPES,
} from "../../types";
import { addError, checkIfFieldIsEmpty, removeError } from "../../tools";
import moment from "moment";
import { cloneDeep } from "lodash";
import { INCIDENT_INFORMATION_CONSTANTS } from "src/forms/constants";
import { combinedValidations } from "./combinedValidations";

/**
 * Validate phone does not start with 1 or 0 and has a max length of 10 digits.
 *
 * @param field Phone Field to validate.
 */
function validateUSPhoneNumber(field: FormFieldType) {
  if (
    field?.config?.type !== INPUT_TYPES.PHONE ||
    !field?.state?.value?.length
  ) {
    return;
  }
  if (field.state.value[0] === "1" || field.state.value[0] === "0") {
    addError(
      `US area code cannot start with the digit '${field.state.value[0]}'`,
      field.state.errors!
    );
  }
  if (
    field.state.value.length > (field?.validation?.maxCharacters?.value || 12)
  ) {
    addError(
      "US phone numbers cannot have more than 10 digits",
      field.state.errors!
    );
  }
}

/**
 * Format phone field in the XXX-XXX-XXX format and validate number is a valid US phone number.
 *
 * @param field Phone Field to format and validate.
 */
function formatPhoneNumber(field: FormFieldType) {
  if (field?.config?.type !== INPUT_TYPES.PHONE) return;
  if (!field?.state?.value) return "";
  validateUSPhoneNumber(field);
  const tempValue: any = field.state.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (!tempValue) return "";
  let newValue = field.state.value;

  newValue = !tempValue[2]
    ? `${tempValue[1]}`
    : `${tempValue[1]}-${tempValue[2]}${`${
        tempValue[3] ? `-${tempValue[3]}` : ""
      }`}`;

  field.state.value = newValue;
}

/**
 * Validate field's length is greater than 0 if the field is required.
 *
 * @param field Field to validate.
 */
export function validateRequired(field: FormFieldType) {
  const fieldIsEmpty: boolean = checkIfFieldIsEmpty(field.state);
  let errorMessage = "";
  errorMessage = field?.validation?.required?.message
    ? field.validation.required.message
    : `${field.config.label} is required`;
  if (field.config.type === INPUT_TYPES.ATTRIBUTE_EDITOR) {
    for (const item of field.state.value) {
      if (item.value) {
        removeError(errorMessage, field.state.errors!);
        return;
      }
    }
    addError(errorMessage, field.state.errors!);
    return;
  }
  if (field?.validation?.required?.value && fieldIsEmpty) {
    addError(errorMessage, field.state.errors!);
  } else {
    removeError(errorMessage, field.state.errors!);
  }
}

/**
 * Validate field contents match the regex in the validation object.
 * @param field Field to validate.
 */
export function validateRegExp(field: FormFieldType) {
  if (!field?.state?.value) return;

  if (
    field?.validation?.matchRegExp?.value &&
    !field?.validation?.matchRegExp?.value?.test(field.state.value) &&
    field.state.value.length < (field?.validation?.maxCharacters?.value || 500)
  ) {
    if (field?.validation?.matchRegExp?.message) {
      addError(field?.validation.matchRegExp.message, field.state.errors!);
      return;
    }
    addError(
      `${field.config.label} has an invalid format.`,
      field.state.errors!
    );
  }
}

/**
 * Validate field only includes characters in the allowedCharactersRegExp, remove the
 * characters if the trim flag is true.
 *
 * @param field Field to validate.
 * @param trim Remove unallowed characters if flag is true.
 */
export function handleIllegalCharacters(field: FormFieldType, trim: boolean) {
  if (!field?.state?.value || typeof field.state.value !== "string") return;
  //Remove HTML tags and entities
  field.state.value = field.state.value
    .replace(new RegExp(/<([^>]+)>/gi), "")
    .replace(new RegExp(/&[^\s]*;/gi), "");
  if (field?.validation?.allowedCharactersRegExp?.value) {
    if (
      field?.validation?.allowedCharactersRegExp?.value.test(field.state.value)
    ) {
      addError(
        field?.validation?.allowedCharactersRegExp?.message ||
          "Attempted to enter an invalid character",
        field.state.errors!
      );
    }
    if (trim) {
      field.state.value = field.state.value.replace(
        new RegExp(field?.validation?.allowedCharactersRegExp?.value, "g"),
        ""
      );
    }
  }
}
/**
 * Validate date field is not in the future.
 *
 * @param field Field to validate.
 * @param validation Parameter to check if validation is DATE_IS_NOT_IN_FUTURE.
 */
function validateDateNotInTheFuture(
  field: FormFieldType,
  validation: CUSTOM_VALIDATION_TYPES
) {
  if (validation === CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_IN_FUTURE) {
    const date = moment(field.state.value, "YYYY/MM/DD");
    if (!date.isSameOrBefore(moment(), "day")) {
      addError(
        `${field.config.label} cannot be in the future`,
        field.state.errors!
      );
    }
  }
}
/**
 * Validate date field is not in the past.
 *
 * @param field Field to validate.
 * @param validation Parameter to check if validation is DATE_IS_NOT_IN_PAST.
 */
function validateDateNotInThePast(
  field: FormFieldType,
  validation: CUSTOM_VALIDATION_TYPES
) {
  if (validation === CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_IN_PAST) {
    const date = moment(field.state.value, "YYYY/MM/DD");
    if (!date.isSameOrAfter(moment(), "day")) {
      addError(
        `${field.config.label} cannot be in the past`,
        field.state.errors!
      );
    }
  }
}

/**
 * Validate date field is not 5 years or more in the past.
 *
 * @param field Field to validate.
 * @param validation Parameter to check if validation is DATE_IS_NOT_5_YEARS_IN_THE_PAST.
 *
 */
function validateDateNot5YearsInThePast(
  field: FormFieldType,
  validation: CUSTOM_VALIDATION_TYPES
) {
  if (validation === CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_5_YEARS_IN_THE_PAST) {
    const date = moment(field.state.value, "YYYY/MM/DD");
    if (!date.isSameOrAfter(moment().subtract(5, "years"), "day")) {
      addError(
        `${field.config.label} cannot be more than 5 years in the past`,
        field.state.errors!
      );
    }
  }
}
/**
 * Runs all single date validations, adds the errors found if any to the field.state.errors
 * array.
 *
 * @param field Field to validate.
 * @param validation CUSTOM_VALIDATION_TYPES array that can include
 * DATE_IS_NOT_5_YEARS_IN_THE_PAST, DATE_IS_NOT_IN_PAST, and/or DATE_IS_NOT_IN_FUTURE.
 */
export function validateDate(
  field: FormFieldType,
  validations: CUSTOM_VALIDATION_TYPES[]
) {
  if (!field?.state?.value || field.config.type !== INPUT_TYPES.DATE_PICKER)
    return;
  const date = moment(field.state.value, "YYYY/MM/DD");

  if (!date.isValid()) {
    addError(`${field.config.label} has an invalid date`, field.state.errors!);
    return;
  }
  if (!validations) return;
  for (const validation of validations) {
    validateDateNotInTheFuture(field, validation);
    validateDateNotInThePast(field, validation);
    validateDateNot5YearsInThePast(field, validation);
  }
}
/**
 * Runs datetime validations based on the validationType, parses datetime and returns
 * true if the date time is in the past or false if it is in the future.
 *
 * @param dateTime Date time object to validate that includes both date and time string fields.
 * @param validationType Can be DATE_TIME_IS_NOT_IN_FUTURE.
 */
export function validateDateTime(
  dateTime: { date: string; time: string },
  validationType: CUSTOM_VALIDATION_TYPES
): boolean | "error" {
  if (!dateTime || !dateTime.date || !dateTime.time) {
    return "error";
  }
  const parsedDateTime = moment(
    `${dateTime.date} ${dateTime.time}`,
    "YYYY-MM-DD HH:mm",
    true
  );

  if (!parsedDateTime.isValid()) {
    return "error";
  }
  if (validationType === CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE) {
    return moment(parsedDateTime).isBefore(moment(), "minute");
  }
  return false;
}

/**
 * Runs maximum amount of characters validation and appends
 * "Characters over limit: {amount over limit}" string to field errors
 * if length is over limit.
 *
 * @param field Field to validate, value must be a string.
 */
export function validateMaxCharacters(field: FormFieldType) {
  if (typeof field?.state?.value != "string") return;
  let upperLimit = 500;

  if (field.config.name === INCIDENT_INFORMATION_CONSTANTS.LOSS_DESCRIPTION) {
    upperLimit = 5000;
  }
  if (
    field.state.value?.length >=
    upperLimit + (field.validation.maxCharacters?.value || 0)
  ) {
    field.state.value = field.state.value.slice(
      0,
      upperLimit + (field.validation.maxCharacters?.value || 0)
    );
  }
  if (
    field.validation.maxCharacters &&
    field.state.value.length > field.validation.maxCharacters.value
  ) {
    const errorMessage =
      field?.validation?.maxCharacters?.message ||
      `${field.config.label} cannot be more than ${field.validation.maxCharacters?.value} characters long.`;
    addError(
      `${errorMessage} (Characters over limit: ${
        field.state.value.length - field.validation.maxCharacters?.value
      })`,
      field.state.errors!
    );
  }
}
/**
 * Runs minimum amount of characters validation add adds error if any, to field errors.
 *
 * @param field Field to validate, value must be a string.
 */
export function validateMinCharacters(field: FormFieldType) {
  if (
    !field?.state?.value ||
    typeof field?.state?.value !== "string" ||
    !field?.validation?.minCharacters
  )
    return;
  if (field.state.value.length < field.validation.minCharacters.value) {
    const errorMessage =
      field?.validation?.minCharacters?.message ||
      `${field.config.label} cannot be less than ${field.validation.minCharacters.value} characters`;
    addError(errorMessage, field.state.errors!);
  }
}

/**
 * Calculates the remaining characters for textarea fields before reaching the maximum
 * allowed characters length and adds the legend to the field's constraint text.
 *
 * @param field Field to calculate the remaining characters of, must be a TEXTAREA field.
 */
function calculateRemainingCharacters(field: FormFieldType) {
  if (field.config.type === INPUT_TYPES.TEXTAREA) {
    if (
      field.state.value &&
      field.validation.maxCharacters &&
      field.state.value.length <= field.validation.maxCharacters.value
    ) {
      field.state.constraintText = `Characters remaining ${
        field.validation.maxCharacters.value - field.state.value.length
      }`;
    } else {
      field.state.constraintText = "";
    }
  }
}
/**
 * Clears field value if the user selects the "-- Clear Selection --" value in a dropdown.
 *
 * @param field Field to clear the value of.
 */
function clearSelect(field: FormFieldType) {
  if (field?.state?.value?.value === "___NO_VALUE___") field.state.value = "";
}

/**
 * Runs formatting functions on the field if the validations are present.
 *
 * @param field Field to run formatting on.
 */
function formatField(field: FormFieldType) {
  if (!field.state.value || typeof field.state.value !== "string") {
    return;
  }
  if (field.validation.changeToLowerCase?.value) {
    field.state.value = field.state.value.toLowerCase();
  }
  if (field.validation.changeToUpperCase?.value) {
    field.state.value = field.state.value.toUpperCase();
  }
  if (field.validation.removeWhiteSpace?.value) {
    field.state.value = field.state.value.replace(/ /g, "");
  }
}
/**
 * Handles all validations for ATTRIBUTE_EDITOR type field.
 *
 * @param field Field to run formatting on.
 * @param formState The current form state to use for validation.
 */
/* eslint-disable complexity */
function validateAttributeList(field: FormFieldType, formState: FormStateType) {
  if (field.config.type !== INPUT_TYPES.ATTRIBUTE_EDITOR) {
    return;
  }
  const {
    maxAttributes,
    minAttributes,
    differentThanAssociateAlias,
    differentThanSubmitterAlias,
    doesNotStartWith,
    duplicateAttributesNotAllowed,
  } = field.validation;
  // Check for component-wide validations, these don't check for individual fields
  if (maxAttributes && field.state?.value?.length > maxAttributes.value!) {
    addError(maxAttributes!.message!, field.state.errors!);
    field.state.value.splice(-1);
  }
  if (minAttributes && field.state?.value?.length < minAttributes.value!) {
    field.state.value = [
      ...cloneDeep(
        formState[field.config.uniqueName || field.config.name].value
      ),
    ];

    field.state.errors = [];
    addError(minAttributes!.message!, field.state.errors!);
  }

  // Create new array of temp fields to use in individual field validations
  const tempFields = [];
  for (const [index, item] of field.state.value.entries()) {
    item.errors = [];
    const tempField: FormFieldType = {
      state: item,
      config: {
        label: `${field.config.label}-${index}`,
        name: `${field.config.name}-${index}`,
        type: INPUT_TYPES.TEXT,
        textInputType: TEXT_INPUT_TYPES.DEFAULT,
      },
      validation: { ...field.validation },
    };
    formatField(tempField);
    tempFields.push(tempField);
  }

  // We need to create the duplicates array after formatting the fields to make
  // sure we're comparing the final edited values correctly with all other values.
  let duplicates = [];
  if (duplicateAttributesNotAllowed) {
    const findDuplicates = (elements: any[]) => {
      const valuesArray = elements.map((x) => x.value);
      return valuesArray.filter((item, index) => {
        return item && valuesArray.indexOf(item) !== index;
      });
    };
    duplicates = findDuplicates(field.state.value);
  }

  // Check for remaining validations that should be for individual fields
  for (const tempField of tempFields) {
    if (!tempField.state?.value) {
      continue;
    }
    if (
      differentThanAssociateAlias?.value &&
      tempField.state.value === field.state.otherInformation.associateAlias
    ) {
      addError(differentThanAssociateAlias!.message!, tempField.state.errors!);
    }
    if (
      differentThanSubmitterAlias?.value &&
      tempField.state.value === field.state.otherInformation.submitterAlias
    ) {
      addError(differentThanSubmitterAlias!.message!, tempField.state.errors!);
    }
    if (
      doesNotStartWith?.value &&
      typeof tempField.state.value === "string" &&
      tempField.state.value.startsWith(doesNotStartWith.value)
    ) {
      addError(doesNotStartWith!.message!, tempField.state.errors!);
    }
    if (duplicates?.length && duplicates.includes(tempField.state.value)) {
      addError(
        duplicateAttributesNotAllowed!.message!,
        tempField.state.errors!
      );
    }
    handleIllegalCharacters(tempField, false);
    validateMaxCharacters(tempField);
    validateMinCharacters(tempField);
  }
}
/* eslint-enable complexity */

/**
 * Changes the field.validation with field.state.validationOverride. Useful for when we
 * need to alter the validation rules for a field during runtime.
 *
 * @param field Field to override validation on.
 * @returns field with new validation rules.
 */
function overrideValidation(field: FormFieldType) {
  if (field.state.validationOverride) {
    field.validation = field.state.validationOverride;
  }
  return field;
}

/**
 * Runs validation for maximum amount of options selected for Multi-select fields.
 *
 * @param field Field run validation on, must be a MULTISELECT field.
 */
function validateMaxOptionsSelected(field: FormFieldType) {
  if (
    field?.config?.type === INPUT_TYPES.MULTISELECT &&
    field?.validation?.maxOptionsSelected &&
    field?.state?.value?.length > field?.validation?.maxOptionsSelected.value
  ) {
    addError(
      `${field.config.label} can only have a maximum of ${field.validation.maxOptionsSelected.value} options selected`,
      field.state.errors!
    );
  }
}

/**
 * Runs validation for minimum amount of options selected for Multi-select fields.
 *
 * @param field Field run validation on, must be a MULTISELECT field.
 */
function validateMinOptionsSelected(field: FormFieldType) {
  if (
    field?.config?.type === INPUT_TYPES.MULTISELECT &&
    field?.validation?.minOptionsSelected &&
    field?.validation?.minOptionsSelected.value > field?.state?.value?.length
  ) {
    addError(
      `${field.config.label} needs to have a minimum of ${field.validation.minOptionsSelected.value} options selected`,
      field.state.errors!
    );
  }
}

/**
 * Runs all of the validations for a field, returns an object with the validated fields
 * and an array of errors. Errors are added to the field.state.errors array which is used
 * to display the errors in the UI.
 *
 * @param field Field run validation on, must be a MULTISELECT field.
 * @param formState The current form state to use for validation.
 * @param except Array of validation types to not run.
 * @returns Object with validated fields and errors list.
 */
export function runAllValidations(
  field: FormFieldType,
  formState: FormStateType,
  except: VALIDATION_TYPES[] = []
) {
  field = cloneDeep(field);
  field.state.errors = [];
  overrideValidation(field);
  const validationTypes = Object.keys(field.validation);
  clearSelect(field);
  formatField(field);

  formatPhoneNumber(field);
  calculateRemainingCharacters(field);

  handleIllegalCharacters(field, true);
  validateMaxCharacters(field);
  validateMaxOptionsSelected(field);
  validateMinOptionsSelected(field);
  if (!except.includes(VALIDATION_TYPES.MIN_CHARACTERS)) {
    validateMinCharacters(field);
  }
  if (!except.includes(VALIDATION_TYPES.DATE)) {
    validateDate(field, validationTypes as CUSTOM_VALIDATION_TYPES[]);
  }
  if (!except.includes(VALIDATION_TYPES.REGEXP)) {
    validateRegExp(field);
  }
  if (!except.includes(VALIDATION_TYPES.REQUIRED)) {
    validateRequired(field);
  }
  validateAttributeList(field, formState);
  const validatedFields = combinedValidations(field, formState);
  const errors: string[] = [];
  Object.values(validatedFields).forEach((entry) => {
    if (entry.showCustomError) {
      errors.push("custom error");
    }
    if (entry.value instanceof Array) {
      for (const subValue of entry.value) {
        if (subValue.errors && subValue.errors.length) {
          errors.push(...subValue.errors);
        }
      }
    }
    return errors.push(...entry.errors!);
  });
  return { validatedFields, errors };
}
