import { v4 } from "uuid";
import type {
  FormFieldStateType,
  FormFieldType,
  FormStateType,
} from "../types";
import type { S3StagedFile, SerializedFile } from "./types";
import { uploadWcClaimAttachment } from "src/api/createWcClaimAttachmentUrlService";
import { addError } from "../tools";
import { ALL_FILE_UPLOAD_FIELDS } from "../constants";

const ACCEPTED_FILE_TYPES =
  ".jpeg, .jpg, .png, .doc, .pdf, .gif, .rtf, .tif, .txt, .docx";
const SINGLE_FILE_MAX_SIZE = 6000000;
const ALL_FILES_MAX_SIZE = 29000000;

const checkTotalFilesSize = (files: File[], errors: string[]) => {
  let totalSize = 0;

  files.forEach((file: File) => {
    if (file != null && "size" in file) {
      totalSize = totalSize + file.size;
    }
  });
  if (totalSize >= ALL_FILES_MAX_SIZE) {
    addError("Total combined files size can't exceed 29MB", errors);
  }
};

const renameFile = (originalFile: File, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

export const replaceSpaceSeparatorsWithNormalSpace = (files: File[]) => {
  const modifiedFiles: File[] = [];
  for (const file of files) {
    // eslint-disable-next-line no-useless-escape
    const fileName = file.name.replaceAll(/\s/g, " ");
    modifiedFiles.push(renameFile(file, fileName));
  }
  return modifiedFiles;
};

export const checkFileSize = (file: File, errors: string[]) => {
  if (file.size >= SINGLE_FILE_MAX_SIZE) {
    addError("File size can't exceed 6MB", errors);
  }
};
export const checkFileTypes = (file: File, errors: string[]) => {
  const extension: string | undefined = file.name
    .toLowerCase()
    .split(".")
    .pop();
  if (!extension || !ACCEPTED_FILE_TYPES?.includes(extension)) {
    addError("Unsupported file type", errors);
  }
};

export const generateS3Key = (file: File, amazonClaimReferenceId: string) => {
  const extension: string | undefined = file.name.split(".").pop();
  return `${amazonClaimReferenceId}/${v4()}.${extension}`;
};

export const checkDuplicateFiles = (
  files: File[],
  fileToCheck: File,
  errors: string[]
) => {
  const duplicates = files.filter(
    (file: File) => file.name === fileToCheck.name
  );
  if (duplicates.length > 1) {
    addError(
      "You are attempting to upload what appears to be a duplicate file",
      errors
    );
  }
};

export const prepareFilesForStorage = (
  files: S3StagedFile[],
  fieldState: FormFieldStateType,
  amazonClaimReferenceId: string
): SerializedFile[] => {
  return files.map((file: S3StagedFile) => {
    const fileFound = fieldState.value?.find(
      (serializedFile: SerializedFile) => {
        return file.name === serializedFile.name;
      }
    );
    if (fileFound) {
      return fileFound;
    }
    return {
      s3Key: file.s3Key || generateS3Key(file, amazonClaimReferenceId),
      name: file.name,
      lastModified: file.lastModified,
      type: file.type,
      size: file.size,
      webkitRelativePath: file.webkitRelativePath,
    };
  });
};
export const convertFilesToS3StagedFiles = (
  files: File[],
  amazonClaimReferenceId: string
) => {
  const s3StagedFiles: S3StagedFile[] = [];
  files.forEach((file: File) => {
    const s3File: S3StagedFile = file;
    s3File.s3Key = generateS3Key(file, amazonClaimReferenceId);
    s3StagedFiles.push(s3File);
  });
  return s3StagedFiles;
};
export const selectFilesForUpload = (
  files: File[],
  fieldState: FormFieldStateType
) => {
  return files.filter((file: File) => {
    const fileFound = fieldState.value?.find(
      (serializedFile: SerializedFile) => serializedFile.name === file.name
    );
    // If file is not found in fieldState, return it for upload
    return !fileFound;
  });
};
export const uploadFile = async (file: S3StagedFile) => {
  return await uploadWcClaimAttachment(
    {
      key: file.s3Key,
      contentLength: file.size,
    },
    file
  );
};
export const transformSerializedFilesToFiles = (
  serializedFiles: SerializedFile[]
) => {
  const result: File[] = [];
  if (!serializedFiles.length) {
    return result;
  }
  serializedFiles.forEach((file: SerializedFile) => {
    result.push(new File([new ArrayBuffer(file.size)], file.name, { ...file }));
  });
  return result;
};

export const validateUploadedFiles = (files: File[], errors: string[]) => {
  checkTotalFilesSize(files, errors);
  for (const file of files) {
    checkFileTypes(file, errors);
    checkFileSize(file, errors);
    checkDuplicateFiles(files, file, errors);
    if (errors.length) {
      return;
    }
  }
};

export const getFilesFromOtherFields = (
  field: FormFieldType,
  formState: FormStateType
) => {
  const files: SerializedFile[] = [];
  Object.entries(formState).forEach(([key, fieldState]) => {
    if (ALL_FILE_UPLOAD_FIELDS.includes(key) && key !== field.config.name) {
      files.push(...fieldState.value);
    }
  });
  return transformSerializedFilesToFiles(files);
};

export const validateAndUploadFiles = async (
  inputFiles: File[],
  field: FormFieldType,
  amazonClaimReferenceId: string,
  formState: FormStateType
) => {
  const errors: string[] = [];
  field.state.errors = [];
  const files = replaceSpaceSeparatorsWithNormalSpace(inputFiles);
  if (files.length < field.state.value.length) {
    const filesForStorage = prepareFilesForStorage(
      files,
      field.state,
      amazonClaimReferenceId
    );
    field.state.value = [...filesForStorage];
    // No need to upload files that are already in storage. We're just updating the field.state.
    return field.state;
  }
  const filesFromOtherFields = getFilesFromOtherFields(field, formState);

  validateUploadedFiles(
    [...filesFromOtherFields, ...files],
    field.state.errors
  );
  if (field.state.errors.length) {
    return field.state;
  }
  const filesForUpload = selectFilesForUpload(files, field.state);
  const S3StagedFiles = convertFilesToS3StagedFiles(
    filesForUpload,
    amazonClaimReferenceId
  );

  for (const file of S3StagedFiles) {
    try {
      await uploadFile(file);
    } catch (error) {
      addError("Error uploading file. Please try again.", errors);
      field.state.errors = [...errors];
      return field.state;
    }
  }
  const filesForStorage = prepareFilesForStorage(
    S3StagedFiles,
    field.state,
    amazonClaimReferenceId
  );
  field.state.value = [...field.state.value, ...filesForStorage];
  return field.state;
};
