import { FormOverride } from "../../types";
import {
  INCIDENT_INFORMATION_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
} from "../../constants";
import {
  BODY_PART_OPTIONS,
  BODY_SIDE_OPTIONS,
  CONCERNS_OPTIONS,
  PRIMARY_IMPACT_OPTIONS,
  SECONDARY_IMPACT_OPTIONS,
} from "src/forms/options";

export const sedgwickFormOverride: FormOverride = {
  state: {
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      hidden: true,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
      hidden: false,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
  },
  config: {
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      label: "Nature of Injury/Primary Impact",
      placeholderText: "Please select the nature of the injury",
      options: PRIMARY_IMPACT_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT]: {
      label: "Cause of Injury/Secondary Impact",
      options: SECONDARY_IMPACT_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      options: BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      options: BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      options: BODY_SIDE_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
      options: BODY_SIDE_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS]: {
      options: CONCERNS_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      contained: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      contained: true,
    },
  },
  validation: {
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      required: { value: false },
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      required: { value: false },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SYMPTOMS_LIST]: {
      required: { value: true },
    },
  },
};
