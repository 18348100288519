import React from "react";
import { useRef, useLayoutEffect, RefObject, useEffect, useState } from "react";
import {
  ColumnLayout,
  Container,
  SpaceBetween,
  Header,
  Alert,
  TextContent,
  Box,
  RadioGroupProps,
  ButtonProps,
  MultiselectProps,
  CheckboxProps,
} from "@amzn/awsui-components-react";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { FileUploadProps } from "@amzn/awsui-components-react/polaris/file-upload";
import {
  FormFieldConfigType,
  INPUT_TYPES,
  VALIDATION_TYPES,
  FormValidationType,
  FormStateType,
  FormFieldType,
  FormFieldStateType,
} from "./types";
import { filterAmazonSites } from "./tools";
import moment from "moment";
import { renderFormField } from "./formComponentRenderers";

import type { RootState } from "../redux/store";
import {
  getEmployeeDetails,
  saveForm,
  setAlert,
  setFormAsInvalid,
  setLoaded,
  setUploadingFile,
  singleFieldValidate,
} from "./formsSlice";
import { cloneDeep } from "lodash";
import { getWcClaim } from "src/app/appSlice";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
} from "./constants";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setValueInAppState } from "../app/appSlice";
import { CUSTOM_ERRORS } from "./customErrors";
import { isDevEnvironment } from "src/environmentInfo/EnvironmentInfo";
import { validateAndUploadFiles } from "./FileUpload/FileUploadUtilities";
import FormFlashBar from "./FormFlashBar";

type MergedChangeDetail =
  | InputProps.ChangeDetail
  | SelectProps.ChangeDetail
  | MultiselectProps.MultiselectChangeDetail
  | CheckboxProps.ChangeDetail;
interface FormContentProps {
  formName: string;
  formConfig: FormFieldConfigType[];
  formValidation: FormValidationType;
  errorIconAriaLabel?: string;
}
function FormContent({
  formName,
  formConfig,
  formValidation,
}: FormContentProps) {
  //Redux Forms state
  const {
    allFormStates,
    gettingEmployeeDetails,
    alert,
    employeeDetailsRetrieved,
    tpaAssigned,
  } = useAppSelector((state: RootState) => state.forms);
  const formState: FormStateType = cloneDeep(allFormStates[formName]);

  //Redux App state
  const { duplicateClaimChecked, amazonClaimReferenceId } = useAppSelector(
    (state: RootState) => state.app
  );
  // Redux Sites state
  const { associateHomeSite, sitesList, listingSites } = useAppSelector(
    (state: RootState) => state.sites
  );

  const dispatch = useAppDispatch();

  /**
   * We need to get the Refs of these components to use them later on for accessibility purposes
   * when we show or hide certain sections of the form so that the focus can correctly move to the
   * right field when it is shown.
   *
   */
  const refMap = new Map<string, RefObject<any>>();
  refMap.set(
    INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT,
    useRef<SelectProps.Ref>(null)
  );
  refMap.set(
    INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART,
    useRef<SelectProps.Ref>(null)
  );
  refMap.set(
    PHYSICIAN_INFORMATION_CONSTANTS.ADD_PHYSICIAN,
    useRef<ButtonProps.Ref>(null)
  );
  refMap.set(
    PHYSICIAN_INFORMATION_CONSTANTS.PHYSICIAN_INFORMATION_1_FIRST_NAME,
    useRef<InputProps.Ref>(null)
  );
  refMap.set(
    INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_AVAILABLE,
    useRef<RadioGroupProps.Ref>(null)
  );
  refMap.set(
    INCIDENT_INFORMATION_CONSTANTS.WITNESS_INFORMATION_1_FIRST_NAME,
    useRef<InputProps.Ref>(null)
  );
  const [focusElement, setFocus] = useState(null);
  useLayoutEffect(() => {
    if (focusElement && refMap.has(focusElement)) {
      refMap.get(focusElement)?.current?.focus();
      setFocus(null);
    }
  }, [focusElement, refMap]);

  /**
   * Runs on component mount
   */
  useEffect(() => {
    // Mark form as loaded on mount
    dispatch(setLoaded({ value: true }));

    //Enable Associate Information Fields on dev environments
    if (
      isDevEnvironment() &&
      formName === ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME
    ) {
      updateForm({
        [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME]: {
          ...formState[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME],
          disabled: false,
        },
        [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME]: {
          ...formState[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME],
          disabled: false,
        },
        [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME]: {
          ...formState[
            ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME
          ],
          disabled: false,
        },
      });
    }
  }, []);

  /**
   * Populate list of sites
   */
  useEffect(() => {
    if (
      formName === INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME &&
      associateHomeSite?.code
    ) {
      const sites = filterAmazonSites(associateHomeSite.code, sitesList);
      updateForm({
        ...formState,
        [INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME]: {
          ...formState[INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME],
          options: sites || [],
          loading: listingSites,
        },
      });
    }
  }, [listingSites, sitesList]);

  /**
   * updateForm saves the formState to the Redux store, it takes the partial or full formState
   * which includes the fields that need to be saved and skips validation.
   * @param formState Partial or full formState to save.
   */
  function updateForm(formState: FormStateType): void {
    dispatch(saveForm({ formName, formState }));
  }

  /**
   * validateFieldAndUpdateForm dipatches the singleFieldValidate action which validates a single
   * field and updates the form with the resulting value an errors if any.
   * @param field Field to validate and update in the form state.
   * @param validationExceptions Array of validations to not run.
   */
  function validateFieldAndUpdateForm(
    field: FormFieldType,
    validationExceptions: VALIDATION_TYPES[] = []
  ) {
    dispatch(singleFieldValidate({ formName, field, validationExceptions }));
  }

  /**
   * checkDuplicateClaim calls the API and waits for a response to see if there is a
   * similar claim in the database to alert the user.
   * @param field should be incidentDate and have a valid value for the function to resolve.
   *
   */
  async function checkDuplicateClaim(field: FormFieldType) {
    const associateInformation =
      allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME];
    const associateEmployeeId = associateInformation.associateEmployeeId;
    if (
      field.config.name === INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE &&
      associateEmployeeId &&
      moment(field.state.value, "YYYY-MM-DD", true).isValid() &&
      !duplicateClaimChecked
    ) {
      dispatch(
        getWcClaim({
          associateEmployeeId: associateEmployeeId.value,
          incidentDate: field.state.value.replace(/-/g, "/"),
        })
      );
    }
  }
  function shouldTriggerModal(field: FormFieldType) {
    /**
     * Trigger reset form modal only when incident site or date have already been
     * set or employee data retrieved and user wants to edit alias or ID
     */

    const incidentSiteFields: string[] = [
      INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_INCIDENT_SITE,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE,
    ];
    // Check if user is changing the incident site after the TPA is assigned
    const changingIncidentSite =
      tpaAssigned && incidentSiteFields.includes(field.config.name);

    // Check if user is changing the incident date after the TPA is assigned
    const changingIncidentDate =
      tpaAssigned &&
      field.config.name === INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE;

    // Check if user is changing the employee details after fetching them
    const changingEmployeeDetails =
      employeeDetailsRetrieved &&
      (field.config.name ===
        ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID ||
        field.config.name === ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS);
    return (
      changingIncidentSite || changingEmployeeDetails || changingIncidentDate
    );
  }
  function handleSpecialCases(field: FormFieldType) {
    const fieldsToHandle: string[] = [
      ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID,
      ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_SITE_NAME,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.HOME_INCIDENT_SITE,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.LOCATION_TYPE,
      INCIDENT_TIME_AND_PLACE_CONSTANTS.INCIDENT_DATE,
    ];
    if (fieldsToHandle.includes(field.config.name)) {
      if (shouldTriggerModal(field)) {
        const description = (
          <TextContent>
            <Box textAlign="left">
              <div className="modal-text">
                Changing the associate employee&apos;s alias or ID after
                retrieving their information or changing the incident site or
                incident date after setting any of these fields will require
                that the form is reset. <br />
                <br />
                All of the information currently filled out in the form will be
                lost.
                <br />
                <br />
                Are you sure you want to continue?
              </div>
            </Box>
          </TextContent>
        );
        const modalContents = {
          title: "CAUTION! Form will be reset!",
          description,
          cancel: "No",
          confirm: "Yes, Reset Form",
        };
        dispatch(
          setValueInAppState({
            key: "remoteResetFormModalContents",
            value: modalContents,
          })
        );
        dispatch(
          setValueInAppState({ key: "triggerOpenResetFormModal", value: true })
        );
        return false;
      }
    }
    return true;
  }
  function onUpdateField({ detail }: { detail: any }, field: FormFieldType) {
    const value = detail.value
      ? detail.value
      : detail.selectedOption
      ? detail.selectedOption
      : detail.selectedOptions;
    field.state.value = value;
    if (!handleSpecialCases(field)) {
      return;
    }
    dispatch(setFormAsInvalid({ formName }));

    validateFieldAndUpdateForm(field, [
      VALIDATION_TYPES.REGEXP,
      VALIDATION_TYPES.MIN_CHARACTERS,
    ]);
  }
  function customOnUpdateField(field: FormFieldType, onChangeCallback: any) {
    if (!handleSpecialCases(field)) {
      return;
    }
    onChangeCallback();
  }

  function onBlur(field: FormFieldType) {
    if (typeof field.state.value === "string") {
      field.state.value = field.state.value.trim();
    }
    checkDuplicateClaim(field);
    validateFieldAndUpdateForm(field);
  }

  function updateIrfCheckbox(
    fieldState: FormFieldStateType,
    fieldName: string
  ) {
    if (
      fieldName !== FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM ||
      fieldState.errors?.length
    ) {
      return;
    }
    fieldState.validationOverride = {
      required: {
        value: true,
        message: FILE_ATTACHMENTS_CONSTANTS.IRF_REQUIRED_ERROR,
      },
    };
    if (fieldState?.value?.length) {
      updateForm({
        [FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE]: {
          value: false,
          disabled: true,
          errors: [],
          hidden: false,
        },
      });
    } else {
      updateForm({
        [FILE_ATTACHMENTS_CONSTANTS.IRF_OVERRIDE]: {
          value: false,
          disabled: false,
          errors: [],
          hidden: false,
        },
      });
    }
  }
  async function onFileUpload(
    { detail }: { detail: FileUploadProps.ChangeDetail },
    field: FormFieldType
  ) {
    dispatch(setUploadingFile({ value: field.config.name }));
    await validateAndUploadFiles(
      detail.value,
      field,
      amazonClaimReferenceId,
      formState
    );
    dispatch(setUploadingFile({ value: "" }));
    const fieldName = field.config.uniqueName || field.config.name;
    updateIrfCheckbox(field.state, fieldName);
    updateForm({ [fieldName]: field.state });
  }
  function onSearchEmployee() {
    const associateEmployeeId =
      formState[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_EMPLOYEE_ID];
    const associateAlias =
      formState[ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_ALIAS];
    if (!associateAlias.value || !associateEmployeeId.value) {
      dispatch(
        setAlert({
          text: "Please make sure to fill out both Associate Alias/Login and Associate Employee ID before continuing",
          type: "error",
        })
      );
      return;
    }
    if (associateAlias.errors?.length || associateEmployeeId.errors?.length) {
      dispatch(
        setAlert({
          text: "Please make sure to fix all errors on both Associate Alias/Login and Associate Employee ID before continuing",
          type: "error",
        })
      );
      return;
    }
    dispatch(
      getEmployeeDetails({
        employeeId: associateEmployeeId.value,
        alias: associateAlias.value,
      })
    );
  }
  /**
   * defineOnChangeCallback selects whether to use the default onUpdateField callback function or a custom onChange
   * function that is defined in the form configuration.
   *
   * @param field Field whose config will be read to decide whether the custom onChange function will be used
   *
   */
  function defineOnChangeCallback(field: FormFieldType) {
    const customOnChange = field.config.onChange;
    const onChange = customOnChange
      ? ({ detail }: { detail: MergedChangeDetail }) =>
          customOnUpdateField(field, () =>
            customOnChange({ detail }, formState, updateForm, field, dispatch)
          )
      : ({ detail }: { detail: MergedChangeDetail }) =>
          onUpdateField({ detail }, field);

    return onChange;
  }

  /**
   * defineOnClickCallback selects whether to use the default onClick callback function defined in the form
   * configuration or handle the special case for seaching associates.
   *
   * @param field Field whose config will be read to decide whether the custom onClick function will be used
   *
   */
  function defineOnClickCallback(field: FormFieldType) {
    let onClick = () => {
      field.config.onClick!(formState, updateForm, field, setFocus);
    };
    if (
      field.config.name === ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE
    ) {
      onClick = () => {
        onSearchEmployee();
      };
      field.state.loading = gettingEmployeeDetails;
    }

    return onClick;
  }
  /**
   * buildFormField returns a build FormFieldType object which has the three required sections of a form field:
   * state, config and validation.
   *
   * @param fieldConfig FormConfig to retrieve the state and validation of the field.
   *
   */
  function buildFormField(fieldConfig: FormFieldConfigType) {
    const fieldName = fieldConfig.uniqueName || fieldConfig.name;
    return {
      state: formState[fieldName],
      config: { ...fieldConfig },
      validation: formState[fieldName]?.validationOverride
        ? formState[fieldName].validationOverride!
        : {
            ...formValidation[fieldName],
          },
    };
  }
  /**
   * renderFieldGroup creates the form fields dynamically, it receives a FormFieldGroup type
   * and iterates recursively through it while getting the field state and validations from the config name.
   * to be used in the input components.
   *
   * @param fieldGroup For the top level of the form this should be the field group that encompasses the
   * whole form in the current page
   *
   */
  function renderFieldGroup(fieldGroup: any) {
    const formPage: any = [];
    const formFields = fieldGroup?.fields.map((fieldConfig: any) => {
      if (!fieldConfig) return null;
      const field: FormFieldType = buildFormField(fieldConfig);
      if (!field.state || field.state.hidden) return null;

      // If field is a group type we recurr through renderFieldGroup to render the fields inside the group field
      if (fieldConfig.type === INPUT_TYPES.FIELD_GROUP) {
        return renderFieldGroup(fieldConfig);
      }
      const onChange = defineOnChangeCallback(field);
      const onClick = defineOnClickCallback(field);
      const fieldName = fieldConfig.uniqueName || fieldConfig.name;
      const ref = refMap.has(fieldName) ? refMap.get(fieldName) : null;

      return renderFormField(
        field,
        onChange,
        onBlur,
        onFileUpload,
        onClick,
        ref
      );
    });
    if (fieldGroup?.header) {
      formPage.push(
        <Container
          key={`${fieldGroup.header}-container`}
          header={
            <Header
              variant="h2"
              description={fieldGroup.description}
              key={`${fieldGroup.header}-header`}
            >
              {fieldGroup.header}
            </Header>
          }
        >
          <SpaceBetween
            direction="vertical"
            size="m"
            key={`${fieldGroup.header}-space-between-1`}
          >
            {formFields}
          </SpaceBetween>
        </Container>
      );
    } else if (fieldGroup.contained === false) {
      if (fieldGroup.columns) {
        formPage.push(
          <ColumnLayout
            columns={fieldGroup.columns}
            key={`${fieldGroup.label}-columns`}
          >
            {formFields}
          </ColumnLayout>
        );
      } else {
        formPage.push(
          <SpaceBetween
            direction="vertical"
            size="m"
            key={`${fieldGroup.label}-space-between-2`}
          >
            {formFields}
          </SpaceBetween>
        );
      }
    } else {
      formPage.push(
        <Container key={`${fieldGroup.label}-container`}>
          <SpaceBetween
            direction="vertical"
            size="m"
            key={`${fieldGroup.label}-space-between-2`}
          >
            {formFields}
          </SpaceBetween>
        </Container>
      );
    }

    return (
      <SpaceBetween
        direction="vertical"
        size="l"
        key={`space-between-${fieldGroup.label}`}
      >
        {formPage}
      </SpaceBetween>
    );
  }
  if (!formConfig || !formState) return null;
  const alertBanner =
    alert?.text || alert?.custom ? (
      <div role="alert">
        <Alert
          statusIconAriaLabel={alert.type}
          type={alert.type}
          header={
            alert.custom
              ? CUSTOM_ERRORS[alert.custom as keyof typeof CUSTOM_ERRORS]
              : alert.text
          }
          data-testid={`${formName}-error-alert`}
        ></Alert>
      </div>
    ) : null;
  return (
    <SpaceBetween direction="vertical" size="l">
      <FormFlashBar formName={formName} />
      {alertBanner}
      {formConfig.map((fieldGroup: FormFieldConfigType) => {
        return renderFieldGroup(fieldGroup);
      })}
    </SpaceBetween>
  );
}

export default FormContent;
