import { isPreProdEnvironment } from "src/environmentInfo/EnvironmentInfo";

/* eslint-disable no-useless-escape */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable no-useless-escape */

export const PHONE_DESCRIPTION_TEXT =
  "Please enter a valid US phone number without country code";
export const PHONE_WITH_EXTENSION = "phoneWithExtension";
export enum INCIDENT_INFORMATION_CONSTANTS {
  FORM_NAME = "incidentInformation",

  QUESTIONABLE_CLAIM = "questionableClaim",
  QUESTIONABLE_CLAIM_REASONS = "questionableClaimReasons",
  QUESTIONABLE_CLAIM_GROUP = "questionableClaimGroup",
  QUESTIONABLE_CLAIM_ADDITIONAL_REASON = "questionableClaimAdditionalReason",
  QUESTIONABLE_CLAIM_AT_LEAST_ONE_REQUIRED_ERROR = "Please select a concern from the provided options or type in your concern under Additional information field if it is not listed. Please ensure that at least one of the fields is filled in.",
  QUESTIONABLE_CLAIM_ERROR_LABEL = "questionableClaimErrorLabel",

  ADD_BODY_PART_NAME = "addBodyPart",
  ADD_BODY_PART_PLACEHOLDER = "Please select the injured body part reported by the associate",
  ADD_ADDITIONAL_BODY_PART = "Add Additional Body Part",
  REMOVE_BODY_PART = "Remove Additional Body Part",

  BODY_PART_INFORMATION = "bodyPartInformation",

  BODY_PART_INFORMATION_0 = "bodyPartInformation_0",
  BODY_PART_INFORMATION_1 = "bodyPartInformation_1",

  PRIMARY_INJURED_BODY_PART = "primaryInjuredBodyPart",
  PRIMARY_INJURED_BODY_PART_DESCRIPTION = "primaryInjuredBodyPartDescription",
  PRIMARY_INJURED_SIDE = "primaryInjuredSide",

  SECONDARY_INJURED_BODY_PART = "secondaryInjuredBodyPart",
  SECONDARY_INJURED_BODY_PART_DESCRIPTION = "secondaryInjuredBodyPartDescription",
  SECONDARY_INJURED_SIDE = "secondaryInjuredSide",
  NATURE_OF_INJURY = "natureOfInjury",
  NATURE_OF_INJURY_DESCRIPTION = "natureOfInjuryDescription",
  SECONDARY_NATURE_OF_INJURY = "secondaryNatureOfInjury",
  SECONDARY_NATURE_OF_INJURY_DESCRIPTION = "secondaryNatureOfInjuryDescription",
  PRIMARY_IMPACT = "primaryImpact",
  PRIMARY_IMPACT_DESCRIPTION = "primaryImpactDescription",
  SECONDARY_IMPACT = "secondaryImpact",
  SECONDARY_IMPACT_DESCRIPTION = "secondaryImpactDescription",
  SYMPTOMS_LIST = "symptomsList",
  LOSS_DESCRIPTION = "lossDescription",
  REFERRAL_TYPE = "referralType",

  INCIDENT_DESCRIPTION_PLACEHOLDER = "Describe briefly on how the incident/injury occurred and include station number or location in building, product description (size, weight, dimensions, ext.), equipment used (box cutter, pallet jack, PIT, etc.), if available.\n\nAdditional body parts: If there are more than two body parts to report, please list body parts and associate details in this section.",

  WINESS_INFORMATION = "witnessInformation",
  WITNESS_INFORMATION_LIST = "witnessInformationList",
  WITNESS_INFORMATION_AVAILABLE = "witnessInformationAvailable",

  WITNESS_INFORMATION_0 = "witnessInformation_0",
  WITNESS_INFORMATION_0_ALIAS = "witnessInformation_0_alias",
  WITNESS_INFORMATION_0_FIRST_NAME = "witnessInformation_0_firstName",
  WITNESS_INFORMATION_0_LAST_NAME = "witnessInformation_0_lastName",
  WITNESS_INFORMATION_0_EMAIL = "witnessInformation_0_email",
  WITNESS_INFORMATION_0_PHONE = "witnessInformation_0_phone",

  WITNESS_INFORMATION_1 = "witnessInformation_1",
  WITNESS_INFORMATION_1_ALIAS = "witnessInformation_1_alias",
  WITNESS_INFORMATION_1_FIRST_NAME = "witnessInformation_1_firstName",
  WITNESS_INFORMATION_1_LAST_NAME = "witnessInformation_1_lastName",
  WITNESS_INFORMATION_1_EMAIL = "witnessInformation_1_email",
  WITNESS_INFORMATION_1_PHONE = "witnessInformation_1_phone",

  ADD_WITNESS = "addWitness",
  ADD_ADDITIONAL_WITNESS = "Add Additional Witness",
  REMOVE_ADDITIONAL_WITNESS = "Remove Additional Witness",

  TIME_AND_PLACE_FIELD_GROUP = "timeAndPlaceFieldGroup",
  INCIDENT_DETAILS_FIELD_GROUP = "incidentDetailsFieldGroup",

  SOURCE_OF_INJURY = "sourceOfInjury",
}
export enum INCIDENT_TIME_AND_PLACE_CONSTANTS {
  FORM_NAME = "incidentTimeAndPlace",
  ALL_FIELDS = "allIncidentTimeAndPlaceFields",

  INCIDENT_DATE = "incidentDate",
  INCIDENT_TIME = "incidentTime",
  INCIDENT_DATE_5_YEARS_IN_THE_PAST_ERROR = "Incident Date cannot be more than 5 years in the past",
  INCIDENT_DATE_AFTER_EMPLOYER_DATE_ERROR = "Incident Date cannot be after Employer Notified Date",
  INCIDENT_DATE_TIME_IN_THE_FUTURE_ERROR = "Incident Date/Time cannot be in the future",
  INCIDENT_DATE_TIME_AFTER_EMPLOYER_DATE_TIME_ERROR = "Incident Date/Time cannot be after Employer Notified Date/Time",

  EMPLOYER_NOTIFIED_DATE = "employerNotifiedDate",
  EMPLOYER_NOTIFIED_TIME = "employerNotifiedTime",
  EMPLOYER_NOTIFIED_DATE_5_YEARS_IN_THE_PAST_ERROR = "Employer Notified Date cannot be more than 5 years in the past",
  EMPLOYER_NOTIFIED_DATE_BEFORE_INCIDENT_DATE_ERROR = "Employer Notified Date cannot be before Incident Date",
  EMPLOYER_NOTIFIED_DATE_TIME_IN_THE_FUTURE_ERROR = "Employer Notified Date/Time cannot be in the future",
  EMPLOYER_NOTIFIED_DATE_TIME_BEFORE_INCIDENT_DATE_TIME_ERROR = "Employer Notified Date/Time cannot be before Incident Date/Time",

  INCIDENT_SITE_NAME = "incidentSiteName",
  HOME_INCIDENT_SITE = "homeIncidentSite",

  LOCATION_TYPE = "locationType",
}
export enum GENERAL_FORM_CONSTANTS {
  ONLY_NUMBERS_REGEX = "[^-0-9]",
  ONLY_LETTERS_AND_NUMBERS_REGEX = "[^a-zA-Z0-9]",
  EMAIL_INVALID_MESSAGE = "Please provide a valid email address",

  PHONE_REGEX = "^[0-9]{3}-[0-9]{3}-[0-9]{4}$",
  PHONE_INVALID_MESSAGE = "Please provide a valid phone number",

  ZIP_CODE_REGEX = "(^d{5}$)",
  ZIP_CODE_INVALID_MESSAGE = "Please enter a valid Postal Code",
  ZIP_CODE_MAX_CHARACTERS_MESSAGE = "Postal code must be 5 characters long",
  ZIP_CODE_INVALID_CHARACTERS_MESSAGE = "Please enter only numbers for Postal Code",

  EXTENSION_INVALID_CHARACTERS_MESSAGE = "Please enter only numbers for Extension",

  TIME_FORMAT_REGEX = "([01]?[0-9]|2[0-3]):[0-5][0-9]",
  TIME_FORMAT_ERROR = "Please enter time correctly in 24h format (HH:MM)",
}

export enum FILE_ATTACHMENTS_CONSTANTS {
  FORM_NAME = "fileAttachments",
  ATTACHMENT_REQUIRED_ERROR = "At least one attachment must be uploaded",
  IRF_REQUIRED_ERROR = "Please upload IRF document before proceeding",
  AUSTIN_NOTES_REQUIRED_ERROR = "Please upload Austin Notes before proceeding",
  AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION_REQUIRED_ERROR = "Please upload Authorization for Initial Medical Evaluation before proceeding",
  INITIAL_REPORT_FORM = "initialReportFormAttachments",
  AUSTIN_NOTES = "austinNotesAttachments",
  AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION = "authorizationForInitialMedicalEvaluationAttachments",
  STATE_FORMS = "stateFormAttachments",
  WITNESS_STATEMENTS = "witnessStatementAttachments",
  PHOTOS = "photoAttachments",
  OTHER_RELEVANT = "otherRelevantAttachments",
  IRF_OVERRIDE = "irfOverride",
}
export const ALL_FILE_UPLOAD_FIELDS: string[] = [
  FILE_ATTACHMENTS_CONSTANTS.INITIAL_REPORT_FORM,
  FILE_ATTACHMENTS_CONSTANTS.AUSTIN_NOTES,
  FILE_ATTACHMENTS_CONSTANTS.AUTHORIZATION_FOR_INITIAL_MEDICAL_EVALUATION,
  FILE_ATTACHMENTS_CONSTANTS.STATE_FORMS,
  FILE_ATTACHMENTS_CONSTANTS.WITNESS_STATEMENTS,
  FILE_ATTACHMENTS_CONSTANTS.PHOTOS,
  FILE_ATTACHMENTS_CONSTANTS.OTHER_RELEVANT,
];
export enum NOTIFICATIONS_CONSTANTS {
  FORM_NAME = "notifications",
  READ_ONLY_SITE_NOTIFICATIONS = "readOnlySiteNotifications",
}
export enum ASSOCIATE_INFORMATION_CONSTANTS {
  FORM_NAME = "associateInformation",
  ASSOCIATE_HOME_SITE_NAME = "associateHomeSite",
  ASSOCIATE_DETAILS_REQUIRED_ERROR = `Please Enter Employee ID & Alias/Login, then click "Search Associate" to auto populate associate details`,
  ASSOCIATE_EMPLOYEE_ID = "associateEmployeeId",
  ASSOCIATE_ALIAS = "associateAlias",
  ASSOCIATE_FIRST_NAME = "associateFirstName",
  ASSOCIATE_LAST_NAME = "associateLastName",
  WORKER_SUB_TYPE = "workerSubType",
  SUBMIT_ASSOCIATE = "submitAssociate",
  SUPERVISOR_ALIAS = "supervisorAlias",
  SUPERVISOR_NAME = "supervisorName",
}

export enum PHYSICIAN_INFORMATION_CONSTANTS {
  FORM_NAME = "physicianInformation",
  PHYSICIAN_INFORMATION = "physicianInformation",
  PHYSICIAN_INFORMATION_LIST = "physicianInformationList",
  PHYSICIAN_INFORMATION_AVAILABLE = "physicianInformationAvailable",

  PHYSICIAN_INFORMATION_0 = "physicianInformation_0",
  PHYSICIAN_INFORMATION_0_FIRST_NAME = "physicianInformation_0_firstName",
  PHYSICIAN_INFORMATION_0_LAST_NAME = "physicianInformation_0_lastName",
  PHYSICIAN_INFORMATION_0_ADDRESS_LINE_1 = "physicianInformation_0_addressLine1",
  PHYSICIAN_INFORMATION_0_ADDRESS_LINE_2 = "physicianInformation_0_addressLine2",
  PHYSICIAN_INFORMATION_0_CITY = "physicianInformation_0_city",
  PHYSICIAN_INFORMATION_0_STATE = "physicianInformation_0_state",
  PHYSICIAN_INFORMATION_0_ZIPCODE = "physicianInformation_0_zipCode",
  PHYSICIAN_INFORMATION_0_COUNTRY = "physicianInformation_0_country",
  PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION = "physicianInformation_0_phoneWithExtension",
  PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_PHONE_NUMBER = "physicianInformation_0_phoneWithExtension_phoneNumber",
  PHYSICIAN_INFORMATION_0_PHONE_WITH_EXTENSION_EXTENSION = "physicianInformation_0_phoneWithExtension_extension",

  PHYSICIAN_INFORMATION_1 = "physicianInformation_1",
  PHYSICIAN_INFORMATION_1_FIRST_NAME = "physicianInformation_1_firstName",
  PHYSICIAN_INFORMATION_1_LAST_NAME = "physicianInformation_1_lastName",
  PHYSICIAN_INFORMATION_1_ADDRESS_LINE_1 = "physicianInformation_1_addressLine1",
  PHYSICIAN_INFORMATION_1_ADDRESS_LINE_2 = "physicianInformation_1_addressLine2",
  PHYSICIAN_INFORMATION_1_CITY = "physicianInformation_1_city",
  PHYSICIAN_INFORMATION_1_STATE = "physicianInformation_1_state",
  PHYSICIAN_INFORMATION_1_ZIPCODE = "physicianInformation_1_zipCode",
  PHYSICIAN_INFORMATION_1_COUNTRY = "physicianInformation_1_country",
  PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION = "physicianInformation_1_phoneWithExtension",
  PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_PHONE_NUMBER = "physicianInformation_1_phoneWithExtension_phoneNumber",
  PHYSICIAN_INFORMATION_1_PHONE_WITH_EXTENSION_EXTENSION = "physicianInformation_1_phoneWithExtension_extension",

  ADD_PHYSICIAN = "addPhysician",
  ADD_MEDICAL_PROVIDER = "Add Medical Provider",
  REMOVE_MEDICAL_PROVIDER = "Remove Medical Provider",

  TRANSPORTED_TO_HOSPITAL = "transportedToHospital",

  HOSPITAL_INFORMATION = "hospitalInformation",
  HOSPITAL_INFORMATION_NAME = "hospitalInformation_name",
  HOSPITAL_INFORMATION_ADDRESS_LINE_1 = "hospitalInformation_addressLine1",
  HOSPITAL_INFORMATION_ADDRESS_LINE_2 = "hospitalInformation_addressLine2",
  HOSPITAL_INFORMATION_CITY = "hospitalInformation_city",
  HOSPITAL_INFORMATION_STATE = "hospitalInformation_state",
  HOSPITAL_INFORMATION_ZIPCODE = "hospitalInformation_zipCode",
  HOSPITAL_INFORMATION_COUNTRY = "hospitalInformation_country",
  HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION = "hospitalInformation_phoneWithExtension",
  HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_PHONE_NUMBER = "hospitalInformation_phoneWithExtension_phoneNumber",
  HOSPITAL_INFORMATION_PHONE_WITH_EXTENSION_EXTENSION = "hospitalInformation_phoneWithExtension_extension",
  INITIAL_MEDICAL_TREATMENT = "initialMedicalTreatment",
}

export enum SITE_CONFIGURATION_CONSTANTS {
  FORM_NAME = "siteConfiguration",
  SITE_ADDRESS_FIELDS = "siteAddressFields",
  SITE_EMAIL_NOTIFICATION_FIELDS = "siteEmailNotificationFields",
  SITE_NOTIFICATION_WEBHOOK_FIELDS = "siteNotificationWebhookFields",
  CODE = "code",
  NAME = "name",
  UNIT_NUMBER = "unitNumber",
  ADDRESS_LINE_1 = "addressLine1",
  ADDRESS_LINE_2 = "addressLine2",
  CITY = "city",
  STATE = "state",
  ZIP_CODE = "zipCode",
  COUNTRY = "country",
  NOTIFICATION_ALIAS_LIST = "notificationAliasList",
  EMAIL_NOTIFICATION_REQUESTED_BY = "emailNotificationRequestedBy",
  EMAIL_NOTIFICATION_UPDATED_AT_TIMESTAMP = "emailNotificationUpdatedAtTimestamp",
  NOTIFICATION_WEBHOOK = "notificationWebhook",
  NOTIFICATION_WEBHOOK_REQUESTED_BY = "notificationWebhookRequestedBy",
  NOTIFICATION_WEBHOOK_UPDATED_AT_TIMESTAMP = "notificationWebhookUpdatedAtTimestamp",
  LAST_MODIFIED_BY = "lastModifiedBy",
  CLAIMANT_SITE_ADDRESS = "claimantSiteAddress",
  SLACK_WEBHOOK_BASE_URL = "https://hooks.slack.com/workflows/",
  CHIME_WEBHOOK_BASE_URL = "https://hooks.chime.aws/incomingwebhooks/",
}

export enum REVIEW_PAGE_CONSTANTS {
  FORM_NAME = "Review and Submit",
  FORM_DESCRIPTION = "Review all of the information provided, you can go back to previous pages by clicking the edit button on each section",
}
export const VALID = false;
export const INVALID = true;

export const SEDGWICK = "sedgwick";
export const ANCHOR = "anchor";
export const HELMSMAN = "helmsman";
export const ANCHOR_STATES = ["OK", "TX", "LA"];
export const WORKERS_COMPENSATION_TPA_LIST = [SEDGWICK, ANCHOR, HELMSMAN];

/**
 * Set Helmsman cutoff date to 2024-06-01 on pre-prod environments(local, alpha, beta and gamma)
 * and 2024-10-01 in production
 */
export const HELMSMAN_INCIDENT_DATE_CUTOFF = isPreProdEnvironment()
  ? new Date("2024-06-01")
  : new Date("2024-10-01");
export const HELMSMAN_STATES = [
  "AK",
  "AZ",
  "CA",
  "CO",
  "HI",
  "IA",
  "ID",
  "IL",
  "KS",
  "MO",
  "MN",
  "MT",
  "NE",
  "NM",
  "NV",
  "OR",
  "SD",
  "UT",
];
