import React, { useEffect, useState } from "react";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
  Flashbar,
  FlashbarProps,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useCollection } from "@amzn/awsui-collection-hooks";

import {
  PAGINATION_LABELS,
  WC_CLAIM_COLUMN_DEFINITIONS,
} from "../table/tableUtils";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { SIM_TYPES } from "src/services/types";
import SimLinkGenerator from "../simLinkGenerator/SimLinkGenerator";
import TableNoMatchState from "../table/TableNoMatchState";
import TableEmptyState from "../table/TableEmptyState";
import { listWcClaims } from "./mySubmittedClaimsSlice";
import { MetricsPublisher } from "src/metrics";
import ExportToCSVButton from "../searchResults/ExportToCSVButton";
import { CSV_CUSTOM_HEADERS } from "../searchResults/constants";

//Declare metricsPublishers, they will be initialized on first render
let metricsPublisher: MetricsPublisher;
let metricsPublisherVisitedBySite: MetricsPublisher;

const CLAIMS_LIMIT = 100;
/**
 * Creates the MySubmittedClaims table and loads the user's submitted claims on first load.
 * @returns MySubmittedClaims table component
 */
function MySubmittedClaims() {
  //Redux Auth state
  const { user } = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  //Redux MySubmittedClaimsState state
  const { submittedClaims, gettingClaims, listWcClaimsError } = useAppSelector(
    (state: RootState) => state.mySubmittedClaims
  );
  const [flashbarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  /**
   * Get User Submitted Claims on load
   */
  useEffect(() => {
    // wait for user to load, then fetch up to 100 most recent claims
    if (user.id) {
      dispatch(
        listWcClaims({ claimReporterAlias: user.id, limit: CLAIMS_LIMIT })
      );

      // Publish visited metrics once user has been loaded
      metricsPublisher = new MetricsPublisher("mySubmittedClaims");
      metricsPublisherVisitedBySite = new MetricsPublisher(
        "mySubmittedClaimsVisitedBySite"
      );
      metricsPublisher.publishMySubmittedClaimsVisited();
      metricsPublisherVisitedBySite.publishMySubmittedClaimsVisitedBySite(
        user.siteCode
      );

      setFlashBarItems([
        {
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          content: (
            <span>
              There was an error loading your submitted claims. Please reload
              the page. If the error persists please open a{" "}
              <SimLinkGenerator
                simType={SIM_TYPES.REPORT_A_BUG}
                target="_blank"
                ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket for the team to investigate this issue."
              >
                support ticket
              </SimLinkGenerator>{" "}
              to get help with this issue.
            </span>
          ),
          id: "message_1",
          onDismiss: () => setFlashBarItems([]),
        },
      ]);
    }
  }, [user]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    allPageItems,
  } = useCollection(submittedClaims || [], {
    filtering: {
      empty: <TableEmptyState resourceName="Claim" />,
      noMatch: (
        <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
      ),
    },
    pagination: { pageSize: 10 },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "createdAtDate" },
        isDescending: true,
      },
    },
    selection: {},
  });
  return (
    <SpaceBetween
      direction="vertical"
      size="s"
      data-testid="submitted-claims-dashboard"
    >
      {listWcClaimsError ? (
        <Flashbar data-testid="error-flashbar" items={flashbarItems} />
      ) : null}
      <Table
        {...collectionProps}
        data-testid="submitted-claims-table"
        items={items}
        columnDefinitions={WC_CLAIM_COLUMN_DEFINITIONS}
        variant="borderless"
        resizableColumns={false}
        wrapLines={true}
        stripedRows={true}
        loading={gettingClaims}
        header={
          <Header
            actions={
              <ExportToCSVButton
                data={allPageItems as any[]}
                headers={CSV_CUSTOM_HEADERS}
                fileNamePrefix="my-submitted-claims"
              ></ExportToCSVButton>
            }
          >
            My Submitted Claims{" "}
            {submittedClaims?.length >= CLAIMS_LIMIT
              ? `(Most Recent ${CLAIMS_LIMIT})`
              : ""}
          </Header>
        }
        loadingText="Loading Claims"
        filter={
          submittedClaims && submittedClaims.length >= 5 ? (
            <TextFilter
              {...filterProps}
              data-testid="text-filter"
              filteringAriaLabel="Filter claims"
              filteringPlaceholder="Filter claims"
              filteringClearAriaLabel="Clear"
              countText={
                filteredItemsCount
                  ? `${filteredItemsCount} ${
                      filteredItemsCount === 1 ? "match" : "matches"
                    }`
                  : ""
              }
            />
          ) : null
        }
        pagination={
          submittedClaims && submittedClaims.length >= 5 ? (
            <Pagination
              {...paginationProps}
              data-testid="pagination"
              ariaLabels={PAGINATION_LABELS}
            />
          ) : null
        }
      />
    </SpaceBetween>
  );
}

export default MySubmittedClaims;
