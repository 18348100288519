import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";

import { listWcClaimsService } from "../../api/listWcClaims";

import {
  ErrorDetails,
  ListWcClaimsCommandInput,
  WcClaimSummary,
} from "@amzn/ttechclaimintakeservice-client";
import { ParsedClaimSummary } from "./constants";
import { convertTimestampsToDates } from "./tools";

// Define a type for the slice state
export interface SearchResultsState {
  [index: string]: any;
  resultClaims: ParsedClaimSummary[];
  gettingClaims: boolean;
  listWcClaimsError: boolean;
  listWcClaimsErrorList: ErrorDetails[];
  isMoreResults: boolean;
  searchTerm: string;
  searchBy: string;
  startDate?: number;
  endDate?: number;
}
export const initialState: SearchResultsState = {
  resultClaims: [],
  gettingClaims: false,
  listWcClaimsError: false,
  listWcClaimsErrorList: [],
  isMoreResults: false,
  searchTerm: "",
  searchBy: "",
  startDate: 0,
  endDate: 0,
};

/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listWcClaims = createAsyncThunk(
  "searchResults/listWcClaims",
  async (input: ListWcClaimsCommandInput, thunkAPI: any) => {
    let response;
    try {
      response = await listWcClaimsService(input);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
    return response;
  }
);
/*eslint-enable @typescript-eslint/no-unused-vars*/

export const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState,
  reducers: {
    setNewSearchResultsState: (
      state: SearchResultsState,
      action: PayloadAction<{
        newState: SearchResultsState;
      }>
    ) => {
      const { newState } = action.payload;

      return newState;
    },
    setValueInSearchResultsState: (
      state: SearchResultsState,
      action: PayloadAction<{
        key: string;
        value: string | boolean | WcClaimSummary[] | ErrorDetails[];
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },

    setSearchParameters: (
      state: SearchResultsState,
      action: PayloadAction<{
        searchBy: string;
        searchTerm: string;
        startDate?: number;
        endDate?: number;
      }>
    ) => {
      return { ...current(state), ...action.payload };
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        listWcClaims.fulfilled,
        (state: SearchResultsState, action: PayloadAction<any>) => {
          const { claimsList, nextPage } = action.payload;
          state.resultClaims = convertTimestampsToDates([...claimsList]);
          state.isMoreResults = nextPage == "" ? false : true;
          state.gettingClaims = false;
          state.listWcClaimsError = false;
        }
      )
      .addCase(listWcClaims.pending, (state: SearchResultsState) => {
        state.gettingClaims = true;
        state.listWcClaimsError = false;
      })
      .addCase(
        listWcClaims.rejected,
        (state: SearchResultsState, action: any) => {
          const { errors } = action.payload;
          if (errors?.length) {
            state.listWcClaimsErrorList = errors;
          } else state.listWcClaimsErrorList = [];
          state.gettingClaims = false;
          state.listWcClaimsError = true;
        }
      );
  },
});

export const {
  setValueInSearchResultsState,
  setNewSearchResultsState,
  setSearchParameters,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
