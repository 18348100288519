import { useState } from "react";
import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react/polaris";
import { ASSOCIATE_INFORMATION_CONSTANTS } from "./constants";

const PAGE_ONE_MESSAGE = (
  <span>
    <span>
      This form should be used to submit Workers&apos; Compensation referrals in
      the US for sites within Air, GCF, & Sort business lines{" "}
    </span>
    <b>
      <u>except</u>
    </b>
    <span>
      {" "}
      for North Dakota, Washington, and Wyoming. Excluded states and business
      lines will continue to use their existing claim submission process. To
      verify the correct process for your business line, navigate to the{" "}
    </span>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://w.amazon.com/bin/view/Main/Risk_Management/US_Workers_Compensation/ReportingClaim"
    >
      Workers&apos; Compensation Reporting a Claim Wiki.
    </a>
  </span>
);

interface FormFlashBarProps {
  formName: string;
}

const FormFlashBar = ({ formName }: FormFlashBarProps) => {
  // Helper function to check if an item is present in the items array
  function checkIfItemIsInFlashbar(itemId: string) {
    return items.some((item) => item.id === itemId);
  }

  // Helper function to remove an item from the items array
  function dismissItem(itemId: string) {
    if (checkIfItemIsInFlashbar(itemId)) {
      setItems([...items.filter((item) => item.id !== itemId)]);
    }
  }

  // Definition of the pageOneMessage object to be displayed in the Flashbar
  const pageOneMessage: FlashbarProps.MessageDefinition = {
    type: "info",
    dismissible: false,
    dismissLabel: "Dismiss message",
    onDismiss: () => dismissItem("pageOneMessage"),
    content: PAGE_ONE_MESSAGE,
    id: "pageOneMessage",
    statusIconAriaLabel: "Important Information",
  };

  // State to hold the array of messages to be displayed in the Flashbar
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([
    pageOneMessage,
  ]);

  // Don't render for pages other than page 1
  if (formName !== ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME) {
    return null;
  }

  // Render the Flashbar component with the items array as a prop
  return (
    <div className="form-flash-bar">
      <Flashbar items={items} data-testid="form-flash-bar" />
    </div>
  );
};

export default FormFlashBar;
