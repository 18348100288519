import { useEffect } from "react";
import MedicalPanelData from "../medicalPanelData/MedicalPanelData";
import { AppLayout } from "@amzn/awsui-components-react/polaris";
import { PAGE_TITLES } from "src/app/constants";

const MedicalPanelDataPage = () => {
  /**
   * Changes the browser tab/menu bar title to Medical Panel Data when page loads
   */
  useEffect(() => {
    document.title = PAGE_TITLES.MEDICAL_PANEL_DATA;
  }, []);
  return (
    <AppLayout
      content={<MedicalPanelData />}
      navigationHide={true}
      ariaLabels={{
        navigationClose: "Close side menu",
        navigationToggle: "Open Side menu",
      }}
      toolsHide={true}
      contentType="form"
      footerSelector="#app-footer"
      headerSelector="#app-header"
      maxContentWidth={1200}
    />
  );
};
export default MedicalPanelDataPage;
