import React from "react";
import { SIM_TYPES } from "../services/types";

import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
//Separate custom errors into object to avoid storing the React objects in the Redux State
export const INVALID_ASSOCIATE_HOME_SITE_ERROR =
  "invalidAssociateHomeSiteError";
export const CUSTOM_ERRORS = {
  [INVALID_ASSOCIATE_HOME_SITE_ERROR]: (
    <div>
      Nucleus is only launched to Amcare supported sites with GCF, Air &amp;
      Sort business lines. If you&apos;re part of this group, please{" "}
      <SimLinkGenerator target="_blank" simType={SIM_TYPES.REPORT_MISSING_SITE}>
        create a ticket
      </SimLinkGenerator>{" "}
      for us to investigate. All other business lines should use their existing
      process as defined in the{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://w.amazon.com/bin/view/Main/Risk_Management/US_Workers_Compensation/ReportingClaim"
      >
        Workers&apos; Compensation Claims Wiki.
      </a>
    </div>
  ),
};
