import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { listWcClaimsService } from "../../api/listWcClaims";

import {
  ListWcClaimsCommandInput,
  WcClaimSummary,
} from "@amzn/ttechclaimintakeservice-client";
import { ParsedClaimSummary } from "../searchResults/constants";
import { convertTimestampsToDates } from "../searchResults/tools";

// Define a type for the slice state
export interface MySubmittedClaimsState {
  [index: string]: any;
  submittedClaims: ParsedClaimSummary[];
  gettingClaims: boolean;
  listWcClaimsError: boolean;
}
export const initialState: MySubmittedClaimsState = {
  submittedClaims: [],
  gettingClaims: false,
  listWcClaimsError: false,
};

/*eslint-disable @typescript-eslint/no-unused-vars*/
export const listWcClaims = createAsyncThunk(
  "mySubmittedClaims/listWcClaims",
  async (input: ListWcClaimsCommandInput, thunkAPI: any) => {
    const response = await listWcClaimsService(input);
    return response;
  }
);
/*eslint-enable @typescript-eslint/no-unused-vars*/

export const mySubmittedClaimsSlice = createSlice({
  name: "mySubmittedClaims",
  initialState,
  reducers: {
    setNewMySubmittedClaimsState: (
      state: MySubmittedClaimsState,
      action: PayloadAction<{
        newState: MySubmittedClaimsState;
      }>
    ) => {
      const { newState } = action.payload;

      return newState;
    },
    setValueInMySubmittedClaimsState: (
      state: MySubmittedClaimsState,
      action: PayloadAction<{
        key: string;
        value: string | boolean | WcClaimSummary[];
      }>
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        listWcClaims.fulfilled,
        (state: MySubmittedClaimsState, action: PayloadAction<any>) => {
          const { claimsList } = action.payload;
          state.submittedClaims = convertTimestampsToDates([...claimsList]);
          state.gettingClaims = false;
          state.listWcClaimsError = false;
        }
      )
      .addCase(listWcClaims.pending, (state: MySubmittedClaimsState) => {
        state.gettingClaims = true;
        state.listWcClaimsError = false;
      })
      .addCase(listWcClaims.rejected, (state: MySubmittedClaimsState) => {
        state.gettingClaims = false;
        state.listWcClaimsError = true;
      });
  },
});

export const {
  setValueInMySubmittedClaimsState,
  setNewMySubmittedClaimsState,
} = mySubmittedClaimsSlice.actions;

export default mySubmittedClaimsSlice.reducer;
