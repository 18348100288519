import {
  Container,
  SpaceBetween,
  Alert,
  ContentLayout,
  Button,
  Spinner,
  Header,
  Box,
} from "@amzn/awsui-components-react/polaris";
import DataGrid, { DataItem } from "../components/data-grid/DataGrid";
import React, { ReactNode, useEffect, useState } from "react";
import { TextContent } from "@amzn/awsui-components-react/polaris";
import { SIM_TYPES } from "../services/types";
import { RootState } from "src/redux/store";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { resetFormsState } from "src/forms/formsSlice";
import { resetWizardState } from "src/components/wizard/wizardSlice";
import { resetReviewPageState } from "src/reviewPage/reviewPageSlice";
import { resetSitesState } from "src/sites/sitesSlice";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import {
  CLIENT_ERROR_RESPONSES,
  HttpStatusCodes,
  SERVER_ERROR_RESPONSES,
  SUCCESS_RESPONSES,
} from "src/api/types";
export interface ClaimSubmittedProps {
  onBackToEdit: any;
  onStartNewClaim: any;
  claimModel: any;
}
const mockSuccessData: DataItem[] = [
  { label: "Claim number", value: "2J82JR756J07XOUITYVQ1" },
  { label: "Incident Date", value: "02/22/2023" },
  { label: "Claimant Name", value: "Lewis Hamilton" },
  { label: "Incident Site", value: "JFK21" },
  { label: "Reporter Name", value: "Max Verstappen" },
  { label: "Injury Type", value: "Lesion" },
];
function getUnknownErrorData(claimModel: any): DataItem[] {
  return [
    {
      label: "Unknown Error",
      value: (
        <div>
          Oops! Something went wrong while submitting your claim. Please try
          again later. If the issue persists, please open a support{" "}
          <SimLinkGenerator
            target="_blank"
            simType={SIM_TYPES.UNKNOWN_ERROR}
            claimModel={claimModel}
          >
            ticket
          </SimLinkGenerator>
        </div>
      ),
    },
  ];
}

function ClaimSubmitted({
  onBackToEdit,
  onStartNewClaim,
  claimModel,
}: ClaimSubmittedProps) {
  //Redux App state
  const { submitScreenStatus, createWcClaimResponse } = useAppSelector(
    (state: RootState) => state.app
  );

  const [submittedData, setSubmittedData] = useState<DataItem[] | undefined>(
    undefined
  );
  const [claimSubmittedTitleText, setClaimSubmittedTitleText] =
    useState<ReactNode>(null);

  const setMessageType = (statusCode: HttpStatusCodes) => {
    if (SERVER_ERROR_RESPONSES.includes(statusCode)) {
      return "error";
    }

    if (CLIENT_ERROR_RESPONSES.includes(statusCode)) {
      return "error";
    }

    if (SUCCESS_RESPONSES.includes(statusCode)) {
      return "success";
    }

    return "custom";
  };
  /**
   * useEffect that runs when we receive the reponse from the server, the response is analyzed and formatted
   * accordingly if it has any errors
   */
  useEffect(() => {
    if (!createWcClaimResponse?.data) return;
    const { statusCode } = createWcClaimResponse;

    const data: DataItem[] = [];
    if (statusCode === HttpStatusCodes.GATEWAY_TIMEOUT) {
      setClaimSubmittedTitleText(
        <div>
          Oops! Something went wrong while submitting your claim. You can find
          the error details below
        </div>
      );
      data.push({
        label: "",
        value: (
          <TextContent>
            <div>
              <div>
                <b>Timeout Error</b>
                <br />
                We are seeing longer than expected delay in receiving a
                confirmation for your claim. Please note that this does not mean
                your claim was not successfully submitted. We kindly request you
                to keep an eye on your email inbox for a confirmation email
                within the next 15 minutes. In the event that you do not receive
                the email, we kindly request you to retry submitting your claim.
                <br />
                If you encounter any further issues, please don&apos;t hesitate
                to contact our{" "}
                <SimLinkGenerator
                  target="_blank"
                  simType={SIM_TYPES.TIMEOUT_ERROR}
                  claimModel={claimModel}
                >
                  Support Team
                </SimLinkGenerator>{" "}
                for assistance.
              </div>
            </div>
          </TextContent>
        ),
      });
    } else if (
      statusCode &&
      SERVER_ERROR_RESPONSES.includes(statusCode as HttpStatusCodes)
    ) {
      setClaimSubmittedTitleText(
        <div>
          Oops! Something went wrong while submitting your claim. Please try
          again later. If the issue persists, please open a support{" "}
          <SimLinkGenerator
            target="_blank"
            simType={SIM_TYPES.UNKNOWN_ERROR}
            claimModel={claimModel}
          >
            ticket
          </SimLinkGenerator>
        </div>
      );
      data.push({
        label: "",
        value: (
          <TextContent>
            <div>
              {createWcClaimResponse.data?.length ? (
                <div>
                  <b>
                    Please add the following information to the ticket to better
                    assist you with this issue:
                  </b>
                </div>
              ) : null}
            </div>
          </TextContent>
        ),
      });
    } else {
      setClaimSubmittedTitleText(null);
    }
    const type = setMessageType(statusCode as HttpStatusCodes);

    for (const entry of createWcClaimResponse.data) {
      data.push({
        label: entry?.fieldName,
        value:
          type === "error" && entry.errorMessage
            ? entry.errorMessage
            : entry.fieldValue,
        type: entry.errorMessage ? type : "custom",
      });
    }
    setSubmittedData(data);
  }, [createWcClaimResponse]);
  const dispatch = useAppDispatch();
  /**
   * Focus on page header
   */
  useEffect(() => {
    const header = document.querySelector('[title="Claim Submitted Header"]');
    if (header) {
      (header as HTMLElement).tabIndex = 0;
      (header as HTMLElement).focus();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);
  const resetWorkersCompensationApp = () => {
    dispatch(resetFormsState());
    dispatch(resetWizardState());
    dispatch(resetReviewPageState());
    dispatch(resetSitesState());
  };
  const renderWarnings = () => {
    if (!createWcClaimResponse?.warningList?.length) {
      return null;
    }
    const warnings = createWcClaimResponse.warningList.map((warning, index) => {
      return <li key={index}>{warning}</li>;
    });
    return (
      <Alert
        statusIconAriaLabel="Warning"
        type="warning"
        data-testid="claim-submitted-warning-alert"
        header="Warnings!"
      >
        <div role="alert">
          <TextContent>
            <ul>{warnings}</ul>
          </TextContent>
        </div>
      </Alert>
    );
  };

  function renderContents() {
    if (submitScreenStatus === "loading") {
      return (
        <Box textAlign="center">
          <SpaceBetween size="xxl" direction="vertical">
            <Alert
              statusIconAriaLabel="Info"
              header="Loading..."
              data-testid="claim-submitted-loading-alert"
            >
              <span role="alert">
                Thank you for submitting the claim. Please wait on this screen
                while we load the claim details. You will also receive a
                confirmation email shortly.
              </span>
            </Alert>

            <br />
            <Spinner data-testid="claim-submitted-spinner" size="large" />
          </SpaceBetween>
        </Box>
      );
    }
    if (submitScreenStatus === "success") {
      resetWorkersCompensationApp();
      return (
        <Box textAlign="center">
          <SpaceBetween size="xxl" direction="vertical">
            <SpaceBetween size="m" direction="vertical">
              <Alert
                statusIconAriaLabel="Success"
                type="success"
                header="Success!"
                data-testid="claim-submitted-success-alert"
              >
                <span role="alert">
                  Your claim has been successfully submitted! Please see the
                  details below. You will be receiving a confirmation email
                  shortly.
                </span>
              </Alert>
              {renderWarnings()}
            </SpaceBetween>

            <DataGrid
              columns={1}
              itemsPerColumn={3}
              dataItems={
                !submittedData || !submittedData.length
                  ? mockSuccessData
                  : submittedData
              }
              header="Claim Information"
              data-testid="claim-submitted-success-data-grid"
            />

            <Box textAlign="right">
              <Button
                variant="primary"
                onClick={onStartNewClaim}
                data-testid="claim-submitted-start-new-claim-button"
              >
                Start a New Claim
              </Button>
            </Box>
          </SpaceBetween>
        </Box>
      );
    }
    if (submitScreenStatus === "error") {
      const alertText = (
        <div role="alert">
          {claimSubmittedTitleText
            ? claimSubmittedTitleText
            : "Sorry, your claim submission was unsuccessful. Please correct the errors shown below and resubmit the form."}
        </div>
      );
      return (
        <Box textAlign="center">
          <SpaceBetween size="xxl" direction="vertical">
            <Alert
              statusIconAriaLabel="Error"
              type="error"
              header="Error"
              data-testid="claim-submitted-error-alert"
            >
              {alertText}
            </Alert>
            <DataGrid
              columns={1}
              itemsPerColumn={
                submittedData?.length || getUnknownErrorData(claimModel).length
              }
              dataItems={
                !submittedData || !submittedData.length
                  ? getUnknownErrorData(claimModel)
                  : submittedData
              }
              header="Error Details"
              data-testid="claim-submitted-error-data-grid"
            />
            <Box textAlign="right">
              <span style={{ paddingRight: "5px" }}>
                <Button
                  onClick={onBackToEdit}
                  data-testid="claim-submitted-back-to-edit-button"
                >
                  Back to edit
                </Button>
              </span>

              <span style={{ paddingLeft: "5px" }}>
                <Button
                  variant="primary"
                  onClick={onStartNewClaim}
                  data-testid="claim-submitted-start-new-claim-button"
                >
                  Start a New Claim
                </Button>
              </span>
            </Box>
          </SpaceBetween>
        </Box>
      );
    }
  }
  const pageTitles = {
    success: "Claim Submitted",
    loading: "Claim Submission in Progress",
    error: "Error Submitting Claim",
  };
  return (
    <React.Fragment>
      <ContentLayout
        header={
          <SpaceBetween size="m">
            <Header variant="h1">
              <div title="Claim Submitted Header">
                {pageTitles[submitScreenStatus as keyof typeof pageTitles]}
              </div>
            </Header>
          </SpaceBetween>
        }
      >
        <Container>{renderContents()}</Container>
      </ContentLayout>
      {["success", "loading"].includes(submitScreenStatus!) ? (
        <Box textAlign="left" padding={{ left: "xs", top: "xs" }}>
          <TextContent>
            If you need to make any updates to the claim, please follow the
            existing process by creating a{" "}
            <SimLinkGenerator
              ariaLabel="Pressing this link will open a new tab where you will be able to create a ticket to modify the submitted claim if necessary."
              target="_blank"
              claimModel={claimModel}
              simType={SIM_TYPES.CLAIM_SUBMITTED}
            >
              ticket.
            </SimLinkGenerator>
            <br />
          </TextContent>
        </Box>
      ) : null}
    </React.Fragment>
  );
}
export default ClaimSubmitted;
