import {
  GetWcClaimInput,
  GetWcClaimCommand,
} from "@amzn/ttechclaimintakeservice-client";

import { getAuthenticationToken } from "../auth/helpers";
import { setupClaimIntakeClient } from "./setupClaimIntakeClient";
import { MetricsPublisher } from "../metrics/metrics";

export async function getWcClaimService(input: GetWcClaimInput) {
  const token = await getAuthenticationToken();
  if (!token) {
    return null;
  }
  const client = setupClaimIntakeClient(token);
  const command = new GetWcClaimCommand(input);
  const metricsPublisher = new MetricsPublisher("api.getWcClaimService");

  return await client
    .send(command)
    .then((response: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderSuccessMetrics();
      return response;
    })
    .catch((error: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderFatalMetrics();
      return error;
    });
}
