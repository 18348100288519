import {
  ListMedicalPanelFilesCommand,
  ListMedicalPanelFilesCommandInput,
} from "@amzn/ttechclaimintakeservice-client";

import { getAuthenticationToken } from "../../auth/helpers";
import { setupClaimIntakeClient } from "../setupClaimIntakeClient";
import { MetricsPublisher } from "../../metrics/metrics";

export async function listMedicalPanelDataFilesService(
  input: ListMedicalPanelFilesCommandInput
) {
  const token = await getAuthenticationToken();
  if (!token) {
    Promise.reject(new Error("Unauthorized"));
  }
  const client = setupClaimIntakeClient(token);
  const command: any = new ListMedicalPanelFilesCommand(input);
  const metricsPublisher = new MetricsPublisher("api.listMedicalPanelFiles");

  return await client
    .send(command)
    .then((response: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderSuccessMetrics();
      return response;
    })
    .catch((error: any) => {
      metricsPublisher.publishAuthenticationLatencyMetrics();
      metricsPublisher.publishRenderFatalMetrics();
      throw error;
    });
}
