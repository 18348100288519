import { SelectProps } from "@amzn/awsui-components-react";
import { ReactNode } from "react";
import type { AppDispatch } from "src/redux/store";

export enum INPUT_TYPES {
  TEXT = "text",
  TEXTAREA = "textarea",
  SELECT = "select",
  DATE_PICKER = "datePicker",
  TIME = "time",
  RADIO = "radio",
  UPLOAD = "upload",
  BUTTON = "button",
  MULTISELECT = "multiselect",
  PHONE = "phone",
  FIELD_GROUP = "fieldGroup",
  ATTRIBUTE_EDITOR = "attributeEditor",
  READ_ONLY = "readOnly",
  CUSTOM_FIELD = "customField",
  CHECKBOX = "checkbox",
}
export enum TEXT_INPUT_TYPES {
  DEFAULT = "text",
  PASSWORD = "password",
  SEARCH = "search",
  NUMBER = "number",
  EMAIL = "email",
  URL = "url",
}
export enum BUTTON_VARIANTS {
  PRIMARY = "primary",
  NORMAL = "normal",
  LINK = "link",
}
export enum VALIDATION_TYPES {
  REQUIRED = "required",
  REGEXP = "regExp",
  TOGETHER = "together",
  DATE = "date",
  MAX_CHARACTERS = "maxCharacters",
  MIN_CHARACTERS = "minCharacters",
}
export enum CUSTOM_VALIDATION_TYPES {
  AT_LEAST_ONE_REQUIRED = "atLeastOneRequired",
  GREATER_THAN = "greaterThan",
  LESS_THAN = "lessThan",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
  DATE_IS_BEFORE_OR_EQUAL = "dateIsBeforeOrEqual",
  DATE_IS_AFTER_OR_EQUAL = "dateIsAfterOrEqual",
  DATE_IS_NOT_IN_FUTURE = "dateIsNotInFuture",
  DATE_IS_NOT_IN_PAST = "dateIsNotInPast",
  DATE_IS_NOT_5_YEARS_IN_THE_PAST = "dateIsNotFiveYearsInThePast",
  DATE_TIME_IS_BEFORE = "dateTimeIsBefore",
  DATE_TIME_IS_AFTER = "dateTimeIsAfter",
  DATE_TIME_IS_NOT_IN_FUTURE = "dateTimeIsNotInFuture",
  MAX_ATTRIBUTES = "maxAttributes",
  MIN_ATTRIBUTES = "minAttributes",
  DIFFERENT_THAN_ASSOCIATE_ALIAS = "differentThanAssociateAlias",
  DIFFERENT_THAN_SUBMITTER_ALIAS = "differentThanSubmitterAlias",
  DOES_NOT_START_WITH = "doesNotStartWith",
  DUPLICATE_ATTRIBUTES_NOT_ALLOWED = "duplicateAttributesNotAllowed",
  REMOVE_WHITE_SPACE = "removeWhiteSpace",
  MAX_OPTIONS_SELECTED = "maxOptionsSelected",
  MIN_OPTIONS_SELECTED = "minOptionsSelected",
  VALID_COMBINED_VALUE = "validCombinedValue",
}
export interface SelectOptionType {
  value: string;
  label: string;
}
export interface RadioOptionType {
  value: string;
  label: string;
  disabled?: boolean;
}
export const RADIO_YES_NO: RadioOptionType[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
export interface CustomAlertType {
  display: boolean;
  dismissible: boolean;
  type: "error" | "success" | "warning" | "info";
  header: string;
  value: string | React.ReactNode;
  statusIconAriaLabel: string;
  dismissAriaLabel: string;
}
export interface FormFieldStateType {
  value?: any;
  hidden?: boolean;
  errors?: string[];
  ignoreInFinalForm?: boolean;
  disabled?: boolean;
  options?: { value: string; label: string }[];
  constraintText?: string;
  loading?: boolean;
  showCustomError?: boolean;
  otherInformation?: any;
  validationOverride?: FormFieldValidationType;
}
export interface FormStateType {
  [key: string]: FormFieldStateType;
}
export interface ValidationContentsType {
  [key: string]: any;
  value?: any;
  message?: string;
  joinWith?: {
    value?: string;
    message?: string;
    customError?: React.ReactNode | string;
  };
  compareTime?: { value?: string; message?: string };
  compareDate?: { value?: string; message?: string };
  customError?: React.ReactNode | string;
  compareTo?: { value?: string; message?: string };
  acceptedValuesList?: string[];
}
export interface FormFieldValidationType {
  [key: string]: ValidationContentsType | undefined;
  required: { value: boolean; message?: string };
  matchRegExp?: { value: RegExp; message?: string };
  allowedCharactersRegExp?: { value: RegExp; message?: string };
  maxCharacters?: { value: number; message?: string };
  minCharacters?: { value: number; message?: string };
  changeToLowerCase?: { value: boolean };
  changeToUpperCase?: { value: boolean };
  [CUSTOM_VALIDATION_TYPES.AT_LEAST_ONE_REQUIRED]?: {
    value: boolean;
    joinWith: {
      value: string;
      message: string;
      customError?: React.ReactNode | string;
    };
    message: string;
    customError?: React.ReactNode | string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_NOT_IN_FUTURE]?: {
    value: boolean;
    joinWith: { value: string; message: string };
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_AFTER]?: {
    value: boolean;
    joinWith: { value: string; message: string };
    compareTime: { value: string; message: string };
    compareDate: { value: string; message: string };
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_IS_AFTER_OR_EQUAL]?: {
    value: boolean;
    compareTo: { value: string; message: string };
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_IS_BEFORE_OR_EQUAL]?: {
    value: boolean;
    compareTo: { value: string; message: string };
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_TIME_IS_BEFORE]?: {
    value: boolean;
    joinWith: { value: string; message: string };
    compareTime: { value: string; message: string };
    compareDate: { value: string; message: string };
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DATE_IS_NOT_5_YEARS_IN_THE_PAST]?: {
    value: boolean;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.MAX_ATTRIBUTES]?: {
    value: number;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.MIN_ATTRIBUTES]?: {
    value: number;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DIFFERENT_THAN_ASSOCIATE_ALIAS]?: {
    value: boolean;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DIFFERENT_THAN_SUBMITTER_ALIAS]?: {
    value: boolean;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DOES_NOT_START_WITH]?: {
    value: string;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.DUPLICATE_ATTRIBUTES_NOT_ALLOWED]?: {
    value: boolean;
    message: string;
  };
  [CUSTOM_VALIDATION_TYPES.REMOVE_WHITE_SPACE]?: {
    value: boolean;
  };
  [CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE]?: {
    value: boolean;
    message: string;
    joinWith: { value: string; message: string };
    acceptedValuesList: string[];
    order: "first" | "second";
  };
}
export interface FormValidationType {
  [key: string]: FormFieldValidationType;
}
export interface BaseFormField {
  name: string;
  type: string;
  label?: string;

  uniqueName?: string;
  description?: ReactNode;
  placeholderText?: string;
  hasPopover?: boolean;
  popoverText?: string;
  onChange?: (
    { detail }: { detail: any },
    formState: FormStateType,
    updateForm: (formState: FormStateType) => void,
    field: FormFieldType,
    dispatch?: AppDispatch
  ) => any;
  textInputType?: string;
  options?: SelectOptionType[];
  parent?: string;
  arrayIndex?: number;
  constraintText?: string | ReactNode;
  ancestors?: { name: string; index: number }[];
  index?: number;
  variant?: string;
  onClick?: (
    formState: FormStateType,
    updateForm?: (formState: FormStateType) => void,
    field?: FormFieldType,
    setFocus?: any
  ) => any;
  fields?: FormFieldConfigType[];
  removeButtonAriaLabel?: string;
  customAlert?: CustomAlertType;
  checkboxText?: React.ReactNode | string;
  timeFormat?: "hh" | "hh:mm" | "hh:mm:ss";
}
export interface TextInputFormField extends BaseFormField {
  type: INPUT_TYPES.TEXT;
  textInputType:
    | TEXT_INPUT_TYPES.DEFAULT
    | TEXT_INPUT_TYPES.PASSWORD
    | TEXT_INPUT_TYPES.SEARCH
    | TEXT_INPUT_TYPES.NUMBER
    | TEXT_INPUT_TYPES.EMAIL
    | TEXT_INPUT_TYPES.URL;
}
export interface TextAreaFormField extends BaseFormField {
  type: INPUT_TYPES.TEXTAREA;
}
export interface SelectFormField extends BaseFormField {
  type: INPUT_TYPES.SELECT;
  options: SelectOptionType[];
  renderHighlightedAriaLive?: (option: SelectProps.Option) => string;
}
export interface DatePickerFormField extends BaseFormField {
  type: INPUT_TYPES.DATE_PICKER;
}
export interface TimeInputFormField extends BaseFormField {
  type: INPUT_TYPES.TIME;
  timeFormat: "hh" | "hh:mm" | "hh:mm:ss";
}
export interface RadioFormField extends BaseFormField {
  type: INPUT_TYPES.RADIO;
  options: RadioOptionType[];
}
export interface UploadFormField extends BaseFormField {
  type: INPUT_TYPES.UPLOAD;
  acceptedFileTypes?: string[];
  multiple?: boolean;
}
export interface FormActionButton extends BaseFormField {
  type: INPUT_TYPES.BUTTON;
  variant:
    | BUTTON_VARIANTS.PRIMARY
    | BUTTON_VARIANTS.NORMAL
    | BUTTON_VARIANTS.LINK;
  loading?: boolean;
}
export interface MultiselectFormField extends BaseFormField {
  type: INPUT_TYPES.MULTISELECT;
  options: SelectOptionType[];
}
export interface FormFieldGroup extends BaseFormField {
  type: INPUT_TYPES.FIELD_GROUP;
  fields: FormFieldConfigType[];
  contained?: boolean;
  columns?: number;
  header?: string;
}

export interface PhoneFormField extends BaseFormField {
  type: INPUT_TYPES.PHONE;
  textInputType: TEXT_INPUT_TYPES.DEFAULT;
}
export interface AttributeEditorField extends BaseFormField {
  type: INPUT_TYPES.ATTRIBUTE_EDITOR;
  addAttributeText: string;
  emptyAttributeListText: string;
  removeAttributeText: string;
  removeButtonAriaLabel: string;
  itemRemovedAriaLive: string;
}

export interface ReadOnlyField extends BaseFormField {
  type: INPUT_TYPES.READ_ONLY;
  spaceBetween?: "xxxs" | "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";
  emptyValueErrorText?: string;
}

export interface CustomField extends BaseFormField {
  type: INPUT_TYPES.CUSTOM_FIELD;
  generatorFunction: (params: any) => any;
}

export interface CheckboxField extends BaseFormField {
  type: INPUT_TYPES.CHECKBOX;
  checkboxText: React.ReactNode | string;
}

export type FormFieldConfigType =
  | TextInputFormField
  | TextAreaFormField
  | SelectFormField
  | DatePickerFormField
  | TimeInputFormField
  | RadioFormField
  | UploadFormField
  | FormActionButton
  | MultiselectFormField
  | PhoneFormField
  | FormFieldGroup
  | AttributeEditorField
  | ReadOnlyField
  | CustomField
  | CheckboxField;

export type FormFieldType = {
  state: FormFieldStateType;
  config: FormFieldConfigType;
  validation: FormFieldValidationType;
};

export interface FormDescription {
  formState: FormStateType;
  formConfig: FormFieldConfigType[];
  formValidation: FormValidationType;
}
export interface AllFormsType {
  [key: string]: FormDescription;
}
export interface FormFieldOverride {
  [key: string | number | symbol]: any;
}
export interface FormOverride {
  state?: {
    [key: string | number | symbol]: FormFieldOverride;
  };
  config?: {
    [key: string | number | symbol]: FormFieldOverride;
  };
  validation?: {
    [key: string | number | symbol]: FormFieldOverride;
  };
}
