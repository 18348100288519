import { FormFieldConfigType, INPUT_TYPES, CustomField } from "../../types";
import { preProcessFormFields } from "../../tools";
import { SIM_TYPES } from "src/services/types";
import {
  NOTIFICATIONS_CONSTANTS,
  SITE_CONFIGURATION_CONSTANTS,
} from "../../constants";
import React from "react";
import {
  Box,
  Container,
  Button,
  StatusIndicator,
  Alert,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { getSite } from "src/sites/sitesSlice";
import SimLinkGenerator from "src/components/simLinkGenerator/SimLinkGenerator";
import { SiteTypes } from "src/sites/constants";
export const formFieldsConfig: FormFieldConfigType[] = [
  {
    name: "allNotificationsFields",
    type: INPUT_TYPES.FIELD_GROUP,
    contained: true,
    label: "Notifications",
    header: "Review Site Notifications",
    fields: [
      {
        name: NOTIFICATIONS_CONSTANTS.READ_ONLY_SITE_NOTIFICATIONS,
        label: "",
        hasPopover: false,
        type: INPUT_TYPES.CUSTOM_FIELD,

        generatorFunction: (params: any) => {
          const {
            associateHomeSite,
            dispatch,
            associateHomeSiteCode,
            gettingSite,
          } = params;

          if (associateHomeSite) {
            const webhookService =
              associateHomeSite.notificationWebhook?.startsWith(
                SITE_CONFIGURATION_CONSTANTS.SLACK_WEBHOOK_BASE_URL
              )
                ? "Slack"
                : associateHomeSite.notificationWebhook?.startsWith(
                    SITE_CONFIGURATION_CONSTANTS.CHIME_WEBHOOK_BASE_URL
                  )
                ? "Chime"
                : "Unknown";

            //Trim the webhook URL to hide the token
            let trimmedWebhookUrl = "";
            if (webhookService !== "Unknown") {
              const tokenizedWebhookUrl =
                associateHomeSite.notificationWebhook?.split("/");
              const webhookSecret = tokenizedWebhookUrl?.pop();
              if (webhookService === "Slack") {
                tokenizedWebhookUrl?.splice(-3, tokenizedWebhookUrl?.length);
              }
              trimmedWebhookUrl = [
                ...tokenizedWebhookUrl,
                `...${webhookSecret?.slice(-8)}`,
              ]?.join("/");
            }

            return (
              <React.Fragment>
                <Box padding={{ bottom: "s" }}>
                  <Alert
                    type="info"
                    dismissible={false}
                    statusIconAriaLabel="Info"
                  >
                    <span>
                      Site Notification settings are non-editable, if there is
                      incorrect information or if you would like to include
                      additional emails or request any modifications, please
                      create a support{" "}
                      <SimLinkGenerator
                        target="_blank"
                        simType={SIM_TYPES.REQUEST_SITE_MODIFICATION}
                        claimModel={associateHomeSite}
                        ariaLabel="Press this link to open a new tab where you will be able to create a ticket to request Site Information modifications."
                      >
                        ticket.
                      </SimLinkGenerator>
                      <br />
                      You will receive an email notification by default when the
                      claim is submitted.
                    </span>
                  </Alert>
                </Box>
                <Box
                  fontWeight="bold"
                  fontSize="body-m"
                  padding={{ bottom: "xxs" }}
                >{`${associateHomeSite.name} Notification Details`}</Box>

                <Box
                  fontWeight="normal"
                  variant="awsui-key-label"
                  fontSize="body-s"
                  padding={{ bottom: "xxs" }}
                ></Box>
                <Container>
                  <Box>
                    {!associateHomeSite.notificationAliasList?.length ? (
                      <StatusIndicator type="warning">
                        There are no Email Notification Addresses configured for
                        this site
                      </StatusIndicator>
                    ) : (
                      <Box padding={{ bottom: "m" }}>
                        <Box padding={{ bottom: "xxs" }}>
                          <StatusIndicator type="success">{`Email Notifications are configured for this site`}</StatusIndicator>
                        </Box>
                        {associateHomeSite.notificationAliasList.map(
                          (item: string) => {
                            return (
                              <div key={item}>
                                <span key={item}>{`${item}@amazon.com`}</span>
                              </div>
                            );
                          }
                        )}
                      </Box>
                    )}
                  </Box>
                  {!associateHomeSite.notificationWebhook?.length ? (
                    <StatusIndicator type="warning">
                      There is no Notification Webhook configured for this site
                    </StatusIndicator>
                  ) : webhookService === "Unknown" || !trimmedWebhookUrl ? (
                    <StatusIndicator type="warning">
                      The Notification Webhook is configured incorrectly, please
                      contact support to resolve this issue
                    </StatusIndicator>
                  ) : (
                    <Box padding={{ bottom: "xxs" }}>
                      <Box padding={{ bottom: "xxs" }}>
                        <StatusIndicator type="success">{`${webhookService} Webhook is configured for this site`}</StatusIndicator>
                      </Box>
                      {trimmedWebhookUrl}
                    </Box>
                  )}
                </Container>
              </React.Fragment>
            );
          } else {
            return (
              <SpaceBetween direction="vertical" size="s">
                <Alert
                  type="error"
                  dismissible={false}
                  statusIconAriaLabel=""
                  header="Error!"
                >
                  <span>
                    There was an error loading the site notification
                    information, to submit the claim, site information must be
                    loaded correctly. Click on the &quot;Reload Site&quot;
                    button to request the site information again. If the site
                    information is not loaded after clicking the &quot;Reload
                    Site&quot; button, please open a{" "}
                    <SimLinkGenerator
                      target="_blank"
                      simType={SIM_TYPES.REPORT_A_BUG}
                      ariaLabel="Press this link to open a new tab where you will be able to create a ticket to report the issue with the site not being loaded correctly."
                    >
                      support ticket
                    </SimLinkGenerator>{" "}
                    to report the problem.
                  </span>
                </Alert>
                <Button
                  variant="normal"
                  onClick={() => {
                    dispatch(
                      getSite({
                        code: associateHomeSiteCode || "NO_SITE",
                        type: SiteTypes.ASSOCIATE_HOME_SITE,
                      })
                    );
                  }}
                  loading={gettingSite}
                >
                  Reload Site
                </Button>
              </SpaceBetween>
            );
          }
        },
      } as CustomField,
    ],
  },
];

export const formFieldsConfigProcessed = preProcessFormFields(formFieldsConfig);
