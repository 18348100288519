import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction, current } from "@reduxjs/toolkit";
import {
  FormFieldType,
  FormStateType,
  FormValidationType,
  VALIDATION_TYPES,
} from "./types";
import { runAllValidations } from "./WorkersCompensation/validations/validations";
import { allForms } from "./AllForms";
import * as SiteConfiguration from "./WorkersCompensation/SiteConfigurationForm";

import {
  checkIfAncestorsAreHidden,
  compareKeys,
  getFieldConfig,
} from "./tools";
import {
  ASSOCIATE_INFORMATION_CONSTANTS,
  FILE_ATTACHMENTS_CONSTANTS,
  INCIDENT_INFORMATION_CONSTANTS,
  INCIDENT_TIME_AND_PLACE_CONSTANTS,
  INVALID,
  NOTIFICATIONS_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
  REVIEW_PAGE_CONSTANTS,
  SEDGWICK,
  SITE_CONFIGURATION_CONSTANTS,
  VALID,
} from "./constants";
import { cloneDeep } from "lodash";
import { getEmployeeDetailsService } from "../api/getEmployeeDetails";
import { INVALID_ASSOCIATE_HOME_SITE_ERROR } from "./customErrors";
import { getSiteService } from "src/api/sites/getSiteService";
import type { ResponseField } from "src/api/types";
// Define a type for the slice state

type AllFormStatesType = {
  [key: string]: FormStateType;
};
type AlertConfigType = {
  text: string | React.ReactNode;
  type: "error" | "success";
  custom?: string;
};
type InvalidFormsType = { [key: string]: boolean };
type FormLoadedType = { value: boolean };
type FormsStateValues =
  | AllFormStatesType
  | string[]
  | string
  | boolean
  | AlertConfigType
  | InvalidFormsType
  | FormLoadedType
  | undefined;
interface FormsState {
  [key: string]: FormsStateValues;
  allFormStates: AllFormStatesType;
  errors: string[];
  validating: boolean;
  updatedForm: boolean;
  gettingEmployeeDetails?: boolean;
  employeeDetailsRetrieved?: boolean;
  alert?: AlertConfigType;
  invalidForms: InvalidFormsType;
  loaded: FormLoadedType;
  uploadingFile: string;
  workersCompensationTpa: string;
  tpaChangeDetected: boolean;
  tpaAssigned: boolean;
  previousWorkersCompensationTpa: string;
}

export const initialState: FormsState = {
  errors: [],
  validating: false,
  allFormStates: {
    [ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]:
      allForms[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME].formState,
    [INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME]:
      allForms[INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME].formState,
    [INCIDENT_INFORMATION_CONSTANTS.FORM_NAME]:
      allForms[INCIDENT_INFORMATION_CONSTANTS.FORM_NAME].formState,
    [PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME]:
      allForms[PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME].formState,
    [FILE_ATTACHMENTS_CONSTANTS.FORM_NAME]:
      allForms[FILE_ATTACHMENTS_CONSTANTS.FORM_NAME].formState,
    [NOTIFICATIONS_CONSTANTS.FORM_NAME]:
      allForms[NOTIFICATIONS_CONSTANTS.FORM_NAME].formState,
    [SITE_CONFIGURATION_CONSTANTS.FORM_NAME]: SiteConfiguration.formState,
  },
  updatedForm: false,
  gettingEmployeeDetails: false,
  employeeDetailsRetrieved: false,
  alert: { text: "", type: "error", custom: "" },
  invalidForms: {
    [ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]: INVALID,
    [INCIDENT_TIME_AND_PLACE_CONSTANTS.FORM_NAME]: INVALID,
    [INCIDENT_INFORMATION_CONSTANTS.FORM_NAME]: INVALID,
    [PHYSICIAN_INFORMATION_CONSTANTS.FORM_NAME]: INVALID,
    [FILE_ATTACHMENTS_CONSTANTS.FORM_NAME]: INVALID,
    [NOTIFICATIONS_CONSTANTS.FORM_NAME]: INVALID,
    [SITE_CONFIGURATION_CONSTANTS.FORM_NAME]: INVALID,
    [REVIEW_PAGE_CONSTANTS.FORM_NAME]: INVALID,
  },
  loaded: { value: false },
  uploadingFile: "",
  workersCompensationTpa: SEDGWICK,
  tpaChangeDetected: false,
  tpaAssigned: false,
  previousWorkersCompensationTpa: SEDGWICK,
};

export const verifyStateIsValid = (state: FormsState) => {
  if (
    !compareKeys(state, initialState) ||
    !compareKeys(state.allFormStates, initialState.allFormStates) ||
    !compareKeys(state.invalidForms, initialState.invalidForms)
  ) {
    return false;
  }

  for (const key in state.allFormStates) {
    if (
      !compareKeys(state.allFormStates[key], initialState.allFormStates[key])
    ) {
      return false;
    }
  }
  return true;
};

export const getEmployeeDetails = createAsyncThunk(
  "forms/getEmployeeDetails",
  async (
    params: { alias: string; employeeId: string },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thunkAPI: any
  ) => {
    const { alias, employeeId } = params;
    let employee;
    try {
      employee = await getEmployeeDetailsService({
        alias,
        employeeId,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
    // Get employee's site after retrieving their information and add it to the response
    let site;
    if (employee.homeSite) {
      try {
        site = await getSiteService({ code: employee.homeSite });
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    }
    const response = { ...employee, ...site };
    return response;
  }
);
export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    resetFormsState: () => {
      return initialState;
    },
    setValidating: (
      state: FormsState,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      const { value } = action.payload;
      state.validating = value;
      if (value === true) {
        state.errors = [];
      }
    },
    setValueInFormsState: (
      state: FormsState,
      action: PayloadAction<{
        key: string;
        value: FormsStateValues;
      }>
    ) => {
      const { key, value } = action.payload;
      state[key as keyof FormsState] = value;
    },
    resetUpdatedForm: (state: FormsState) => {
      state.updatedForm = false;
    },
    saveForm: (
      state: FormsState,
      action: PayloadAction<{
        formName: string;
        formState: FormStateType;
      }>
    ) => {
      const { formName, formState } = action.payload;
      const currentState = current(state);
      state.allFormStates[formName] = {
        ...currentState.allFormStates[formName],
        ...formState,
      };
      state.updatedForm = true;
    },
    singleFieldValidate: (
      state: FormsState,
      action: PayloadAction<{
        formName: string;
        field: FormFieldType;
        validationExceptions: VALIDATION_TYPES[];
      }>
    ) => {
      const { formName, field, validationExceptions } = action.payload;
      const formState = {
        ...cloneDeep(current(state).allFormStates[formName]),
      };
      const { validatedFields } = runAllValidations(
        field,
        formState,
        validationExceptions
      );
      state.allFormStates[formName] = {
        ...current(state).allFormStates[formName],
        ...validatedFields,
      };
      state.validating = false;
      // This will be used to detect when the form is updated to remove the error banner
      state.updatedForm = true;
    },
    validateAll: (
      state: FormsState,
      action: PayloadAction<{
        formName: string;
        formState?: FormStateType;
        formValidation?: FormValidationType;
      }>
    ) => {
      const { formName, formState, formValidation } = action.payload;

      const currentState = current(state);
      const form = allForms[formName];
      let newFormState =
        formState || cloneDeep(currentState.allFormStates[formName]);
      const formErrors: string[] = [];
      for (const fieldName in newFormState) {
        const fieldConfig = getFieldConfig(fieldName, form.formConfig);
        const fieldValidation =
          formValidation?.[fieldName] || form.formValidation[fieldName];
        if (!fieldConfig || !fieldValidation) continue;
        const field: FormFieldType = {
          state: newFormState[fieldName],
          config: fieldConfig,
          validation: fieldValidation,
        };
        if (
          field.state.hidden ||
          checkIfAncestorsAreHidden(newFormState, field.config)
        )
          continue;
        const { validatedFields, errors } = runAllValidations(
          field,
          newFormState,
          []
        );
        newFormState = { ...newFormState, ...validatedFields };
        formErrors.push(...errors);
      }
      state.allFormStates[formName] = {
        ...currentState.allFormStates[formName],
        ...newFormState,
      };
      if (formErrors.length) {
        state.invalidForms[formName] = INVALID;
      } else {
        state.invalidForms[formName] = VALID;
      }
      state.errors = formErrors;
      state.validating = false;
    },
    setFormAsInvalid: (
      state: FormsState,
      action: PayloadAction<{
        formName: string;
      }>
    ) => {
      const { formName } = action.payload;
      state.invalidForms[formName] = INVALID;
    },
    setAlert: (
      state: FormsState,
      action: PayloadAction<{
        text: string;
        type: "error" | "success";
      }>
    ) => {
      const { text, type } = action.payload;
      state.alert = { text, type };
    },
    setLoaded: (
      state: FormsState,
      action: PayloadAction<{
        value: boolean;
      }>
    ) => {
      const { value } = action.payload;
      state.loaded.value = value;
    },
    setUploadingFile: (
      state: FormsState,
      action: PayloadAction<{
        value: string;
      }>
    ) => {
      const { value } = action.payload;
      state.uploadingFile = value;
    },
  },

  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(
        getEmployeeDetails.fulfilled,
        (state: FormsState, action: PayloadAction<any>) => {
          state.gettingEmployeeDetails = false;
          if (!action.payload.code) {
            state.alert = {
              text: "",
              custom: INVALID_ASSOCIATE_HOME_SITE_ERROR,
              type: "error",
            };
            return;
          }
          const associateInformationState = current(
            state.allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]
          );
          state.allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME] = {
            ...associateInformationState,
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME
              ],
              value: action.payload.firstName,
              errors: [],
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME
              ],
              value: action.payload.lastName,
              errors: [],
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME
              ],
              value: action.payload.homeSite,
              errors: [],
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE
              ],
              value: action.payload.workerSubType,
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_ALIAS
              ],
              value: action.payload.supervisorAlias,
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.SUPERVISOR_NAME
              ],
              value: action.payload.supervisorName,
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE
              ],
              disabled: true,
            },
          };
          state.invalidForms[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME] = VALID;
          state.alert = {
            text: "Employee details retrieved successfully! Please verify and click Next to continue.",
            type: "success",
          };
          state.employeeDetailsRetrieved = true;
        }
      )
      .addCase(getEmployeeDetails.pending, (state: FormsState) => {
        state.gettingEmployeeDetails = true;
        state.alert = { text: "", type: "error" };
        state.employeeDetailsRetrieved = false;
      })
      .addCase(
        getEmployeeDetails.rejected,
        (state: FormsState, action: PayloadAction<any>) => {
          const associateInformationState = current(
            state.allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME]
          );
          state.allFormStates[ASSOCIATE_INFORMATION_CONSTANTS.FORM_NAME] = {
            ...associateInformationState,
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_FIRST_NAME
              ],
              value: "",
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_LAST_NAME
              ],
              value: "",
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.ASSOCIATE_HOME_SITE_NAME
              ],
              value: "",
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.WORKER_SUB_TYPE
              ],
              value: "",
            },
            [ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE]: {
              ...associateInformationState[
                ASSOCIATE_INFORMATION_CONSTANTS.SUBMIT_ASSOCIATE
              ],
              disabled: false,
            },
          };

          if (action.payload.errors) {
            const alertText = action.payload.errors.map(
              (error: ResponseField) => {
                return error.errorMessage;
              }
            );
            state.alert = {
              text: alertText.join("\n"),
              type: "error",
            };
          } else {
            state.alert = {
              text: "There was an error processing the request for the employee information. Please try again.",
              type: "error",
            };
          }
          state.gettingEmployeeDetails = false;
        }
      );
  },
});

export const {
  setValidating,
  saveForm,
  singleFieldValidate,
  validateAll,
  resetUpdatedForm,
  setFormAsInvalid,
  setAlert,
  setLoaded,
  resetFormsState,
  setUploadingFile,
  setValueInFormsState,
} = formsSlice.actions;

export default formsSlice.reducer;
