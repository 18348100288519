import {
  TTechClaimIntakeClient,
  TTechClaimIntakeClientConfig,
} from "@amzn/ttechclaimintakeservice-client";
import { buildConfigForEnvironment } from "./config";

type Request = {
  headers: {
    [key: string]: string;
  };
  credentials: string;
};

export function setupClaimIntakeClient(token: any) {
  const config: TTechClaimIntakeClientConfig = buildConfigForEnvironment();
  config.maxAttempts = 1;
  const client: TTechClaimIntakeClient = new TTechClaimIntakeClient(config);

  client.middlewareStack.add(
    (next: any) => (args: any) => {
      (args.request as Request).headers["Authorization"] = "Bearer " + token;
      return next(args);
    },
    { step: "build" }
  );
  return client;
}
